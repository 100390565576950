import React,{useState} from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Login() {
	// login
    const nav = useNavigate()
	const [Email, setemail] = useState('');
	const [Password, setpassword] = useState('')
	console.log(Email,Password);

    
	function Adminlogin(e){
	e.preventDefault()
	 axios({
		method: 'post',
		url: 'https://node.firehrm.com/HRMS/HRMS/employee/login',
		data: {
		  email: Email,
		  password: Password
		
		}
	  })
	  .then((response) => {
		console.log(response.data);
		localStorage.setItem('token',response.data.token)
		localStorage.setItem('role',response.data.data[0].role)
		localStorage.setItem('id',response.data.data[0]._id)
		if (localStorage.getItem('role')=='admin'){
			localStorage.setItem('image',response.data.data[0].companyLogo)
			localStorage.setItem('name',response.data.data[0].companyName)
			
		}
		else{
			localStorage.setItem('image',response.data.data[0].profileImage)
			localStorage.setItem('name',response.data.data[0].name)
			localStorage.setItem('email',response.data.data[0].email)
			localStorage.setItem('loginId',response.data.datas._id)
		}
		if (response.data.success=='true'){
			Swal.fire('Login Successfull',"","success")
		   nav('/index')  
		}
		else{
			
		}
		
	  }, (error) => {
		console.log(error);
		Swal.fire(error.response.data.message || error.response.data.errors[0].msg)
	  }
	  );
	 
	}

  return (
    
    <div class="hold-transition theme-primary bg-img cover " style={{backgroundImage: "url(images/auth-bg/bg-1.jpg)"}}>
	
	<div class="container h-p100">
		<div class="row align-items-center justify-content-md-center h-p100">	
			
			<div class="col-12">
				<div class="row justify-content-center g-0">
					<div class="col-lg-5 col-md-5 col-12">
						<div class="bg-white rounded10 shadow-lg">
							<div class="content-top-agile p-20 pb-0">
								<h2 class="text-primary">Let's Get Started</h2>
								<p class="mb-0">Sign in to continue to CRMi.</p>							
							</div>
							<div class="p-40">
								<form action="index.html" method="post">
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-user"></i></span>
											<input onChange={(e)=>setemail(e.target.value)} type="email" class="form-control ps-15 bg-transparent" placeholder="Email"/>
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text  bg-transparent"><i class="fa-solid fa-lock"></i></span>
											<input onChange={(e)=>setpassword(e.target.value)} type="password" class="form-control ps-15 bg-transparent" placeholder="Password"/>
										</div>
									</div>
									  <div class="row">
										<div class="col-6">
										  {/* <div class="checkbox">
											<input value={rememberMe} onChange={(e)=>setrememberMe("true")} type="checkbox" id="basic_checkbox_1" />
											<label htmlFor="basic_checkbox_1">Remember Me</label>
										  </div> */}
										</div>
										{/* <!-- /.col --> */}
										<div class="col-6">
										 <div class="fog-pwd text-end">
											<a href="recoverPassword" class="hover-warning"><i class="ion ion-locked"></i> Forgot pwd?</a><br/>
										  </div>
										</div>
										{/* <!-- /.col --> */}
										<div class="col-12 text-center">
										  <button type="button" onClick={(e)=>Adminlogin(e)} class="btn btn-danger mt-10">SIGN IN</button>
										</div>
										{/* <!-- /.col --> */}
									  </div>
								</form>	
								{/* <div class="text-center">
									<p class="mt-15 mb-0">Don't have an account? <a href="register" class="text-warning ms-5">Sign Up</a></p>
								</div>	 */}
							</div>						
						</div>
						{/* <div class="text-center">
						  <p class="mt-20 text-white">- Sign With -</p>
						  <p class="gap-items-2 mb-20">
							  <a class="btn btn-social-icon btn-round btn-facebook" href="#"><i class="fa-brands fa-facebook-f"></i></a>
							  <a class="btn btn-social-icon btn-round btn-twitter" href="#"><i class="fa-brands fa-twitter"></i></a>
							  <a class="btn btn-social-icon btn-round btn-instagram" href="#"><i class="fa-brands fa-instagram"></i></a>
							</p>	
						</div> */}
					</div>
				</div>
			</div>
		</div>
	</div>

  <Helmet>
  {/* <!-- Vendor JS --> */}
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

  <script src="assets/js/vendors.min.js"></script>
  <script src="assets/js/pages/chat-popup.js"></script>
  <script src="assets/icons/feather-icons/feather.min.js"></script>	

  <script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
  
  {/* <!-- HRMS App --> */}
  <script src="assets/js/jquery.smartmenus.js"></script>
  <script src="assets/js/menus.js"></script>
  <script src="assets/js/template.js"></script>
  <script src="assets/js/pages/dashboard.js"></script>
  <script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
  <script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
  <script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
  {/* <script>
	  $(document).ready(function(){
	 $('.dropdown-toggle').dropdown()
  });
  </script> */}
  </Helmet>
  
</div>
  )
}
