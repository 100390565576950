import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function SingleLoginAndLogout() {

    const [Data, setSingleData] = useState([]);
    const token = localStorage.getItem('token')
    const nav = useNavigate()
    const SingleData = async () => {
        axios({
            'method':'GET',
            'url':'https://node.firehrm.com/HRMS/HRMS/employee/getAll-loginLogoutTime',
            'headers':{
                'Authorization':token 
            }
        })
        .then((response) =>{
            console.log(response.data.data)
             setSingleData(response.data.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    useEffect(() => {SingleData();},[]);


  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="col-11 ms-50">

<div class="box p-50">
   <div class="box-header with-border">
     <h3 class="box-title">Login and Logout Data</h3>
   </div>
   {/* <!-- /.box-header --> */}
   <div class="box-body">
   <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
           <thead>
               <tr>
                   <th>Name</th>
                   <td>{Data?.[0]?.employeeName}</td>
               </tr>
               <tr>
                   <th>ID.NO</th>
                   <td>{Data?.[0]?.identityNumber}</td>
               </tr>
               <tr>
                   <th>Designation</th>
                   <td>{Data?.[0]?.designationName}</td>
               </tr>
           </thead>

       </table>
       </div>
       <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
           <thead>
            
               <tr>
                   <th>Date</th>
                   <th>LogIn Time</th>
                   <th>LogOut Time</th>
               </tr>
            
           </thead>
           <tbody>
           {Data && Data.reverse().map((x=>

            <tr>
              <td>{x?.loginDate}</td>
              <td>{x?.loginTime}</td>
              <td>{x?.logoutTime=='0'? "Loged In" : x?.logoutTime}</td>
            </tr>
            ))}
           </tbody>

       </table>
       </div>
   </div>
   {/* <!-- /.box-body --> */}
 </div>
 {/* <!-- /.box -->       */}
</div>

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>

</div>
</div>
  )
}
