import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import axios from 'axios'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'

export default function ViewAllEmployee() {

    const [employeesListData,setEmployeesData] = useState([]);
    const token = localStorage.getItem('token');
    const navToFullProfile = useNavigate()
    console.log(employeesListData)
    const employeesListDetails = async () => {
        axios({
            'method':'GET',
            'url':'https://node.firehrm.com/HRMS/HRMS/employee/getAll',
            'headers':{
                'Authorization':token 
            }
        })
        .then((response) =>{
            console.log(response)
            setEmployeesData(response.data.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    useEffect(() => {employeesListDetails();},[]);

    function NavToProfile(e,x){
        e.preventDefault()
        navToFullProfile('/viewEmployeeProfile',{state:x})
    }


  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-50">
    
    <Header/>
    <UserSideBar/>
    
    <QuickActionToggle/> 
    
     {/* <!-- Content Wrapper. Contains page content --> */}
    <div class="content-wrapper" style={{minHeight: "225px",width:"1200px",marginLeft:'100px',}}>
   
        <div class="container-full">
          {/* <!-- Content Header (Page header) -->	   */}
          <div class="content-header">
              <div class="d-flex align-items-center">
                  <div class="me-auto">
                      <h3 class="page-title ms-50 mt-50">Employee Profiles</h3>
                      <div class="d-inline-block align-items-center">
                          <nav>
                              <ol class="breadcrumb mt-10">
                                  {/* <li class="breadcrumb-item"><a href="#"><i class="fa-solid fa-house-user"></i></a></li> */}
                                  {/* <li class="breadcrumb-item active" aria-current="page">Employees List</li> */}
                                  <li><a style={{marginLeft:'680px'}} href="addEmployeeProfile" class='btn btn-primary'><i class="fa-solid fa-circle-plus m-5"><span class="path1"></span><span class="path2"></span></i>Add employee</a></li>								
                              </ol>
                              
                          </nav>
                      </div>
                  </div>				
              </div>
          </div>
      {/* <!-- Main content -->	 */}
      {employeesListData && employeesListData.map((x=>
          <section class="content">
              <div class="row">
                  <div class="app-menu" style={{marginBottom:"30px"}}>
                      <ul class="header-megamenu nav">
                          <li class="btn-group d-lg-inline-flex d-none">
                              <div class="app-menu">
                                  <div class="search-bx mx-5">
                                      {/* <form>
                                          <div class="input-group">
                                            <input type="search" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"/>
                                            <div class="input-group-append">
                                              <button class="btn" type="submit" id="button-addon3"><i class="fa-solid fa-magnifying-glass"><span class="path1"></span><span class="path2"></span></i></button>
                                            </div>
                                          </div>
                                      </form> */}
                                  </div>
                              </div>
                          </li>
                      </ul> 
                    </div>	
                  <div class="ms-50 me-0 col-lg-11 col-12">
                      <div class="box">
                          <div class="box-body">	
                              <div class="d-flex flex-wrap align-items-center">							
                                  <div class="me-25 bg-danger-light h-60 w-60 rounded text-center b-1">
                                        <img src={x?.profileImage} class="align-self-center" alt=""/>
                                  </div>
  
                                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-10 pe-15">
                                      <span class="h2 text-dark fw-900 hover-danger fs-20">
                                      {x?.name}
                                      </span>
                                      <h5 class="h2 text-dark fw-600 hover-danger fs-18">
                                          {x?.designation?.designationName}
                                      </h5>
                                  </div>
                              </div>
                              <div class="mt-50">
                                  <h4 class="text-primary mb-20 mt-5">ID NO: {(x?.identityNumber)}</h4>
                                  <p>{x?.contact}<br/>{x?.email}</p>
  
                                  <div class="mt-10 d-flex justify-content-between align-items-center">
                                      <button onClick={(e)=>NavToProfile(e,x)} class="waves-effect waves-light btn btn-primary mb-5">View Profile</button>
                                      <p class="mb-0 text-fade"><i class="fa fa-map-marker"></i> {x?.address}</p>
                                  </div>
                              </div>
                          </div>					
                      </div>
                  </div>
      
              </div>
          </section>
          ))}
          {/* <!-- /.content --> */}
          
          </div>
          

         
          <Helmet>
          {/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>					 */}

</Helmet>

        </div>
      
       
     {/* <!-- /.content-wrapper --> */}
     </div>  
   
     <Footer/> 
     </div>  
  )
}
