import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useLocation } from 'react-router-dom';

export default function UpdateCandidate() {
    const token = localStorage.getItem('token')
    const nav = useNavigate()
    const state = useLocation()
    console.log(state);
    const [candidateName,setCandidate] = useState(state?.state?.screening?.candidateName)
    const [desc,setdesc] = useState(state?.state?.screening?.desc)
    const [firstRoundStatus,setfirstRoundStatus] = useState(state?.state?.screening?.firstRoundStatus)
    const [resumeUpload,setresumeUpload] =  useState(state?.state?.screening?.resumeUpload)
    const [feedBack, setTfeedBack] = useState(state?.state?.TechnicalInterview?.feedBack)
    const [secondRoundStatus, setsecondRoundStatus] = useState(state?.state?.TechnicalInterview?.secondRoundStatus);
    const [nextInterviewSchedule, setnextInterviewSchedule] = useState(state?.state?.screening?.nextInterviewSchedule)
    const [thirdRoundStatus, setthirdRoundStatus] = useState(state?.state?.HRInterview?.thirdRoundStatus)
    const [salaryAmount, setsalaryAmount] = useState(state?.state?.HRInterview?.salaryAmount)
    const [joiningDate, setjoiningDate] = useState(state?.state?.HRInterview?.joiningDate)
    const [nextInterviewDate, setnextInterviewDate] = useState(state?.state?.TechnicalInterview?.nextInterviewDate)
    const screening = {candidateName,desc,resumeUpload,nextInterviewSchedule}
    const TechnicalInterview = {feedBack,nextInterviewDate,secondRoundStatus}
    const HRInterview = {thirdRoundStatus,salaryAmount,joiningDate}
    function updatevacancy(e){
   axios({
       'method': 'PUT',
       'url':'https://node.firehrm.com/HRMS/HRMS/admin/update-interview/'+state?.state?._id,
       'headers':{
           'Authorization':token
       },
       'data': {screening,TechnicalInterview,HRInterview}

   }).then((resp)=>{
       console.log(resp);
         if(resp.status == '200'){nav('/getAllOpenings')}
   } )
   .catch((error)=>{
       console.log(error)
       })
   }
  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150 ms-50 me-50">
        <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="row">

  <div class="col-lg-12">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><strong>UPDATE CANDIDATE</strong></h3>
      </div>
      <div class="panel-body p-50">
<form>
<h2 className='mb-50'>SCREENING ROUND</h2>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
               <label class='col-6'>Candidate Name</label>
                   <h6 class='col-6'>{candidateName}</h6>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                   <label class='col-6'>Description</label>
                   <h6 class='col-6'>{desc}</h6>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                   <label class='col-6'>Screening Round Status</label>
                   <h6 class='col-6'>{firstRoundStatus}</h6>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                   <label class='col-6'>Resume</label>
                   <h6 class='col-6'><iframe src={resumeUpload} frameborder="0"></iframe></h6>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
               <label class='col-6'>Technical Interview Schedule</label>
               <input class='col-6' value={nextInterviewSchedule} type="date" onChange={(e)=>setnextInterviewSchedule(e.target.value)}/>
            </div>
            <hr className='mt-50 mb-50'/>
        <h2 className='mb-50'>TECHNICAL ROUND</h2>
         
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                  <label class='col-6'>Feedback</label>
                  <input class='col-6' id='feedBack' placeholder={feedBack} type="text" onChange={(e)=>setTfeedBack(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                  <label class='col-6'>Technical Round Status</label>
                  <input class='col-6' id='secondRoundStatus' placeholder={secondRoundStatus} type="text" onChange={(e)=>setsecondRoundStatus(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                  <label class='col-6'>HR Interview Schedule</label>
                  <input class='col-6' id='hrInterviewSchedule' placeholder={nextInterviewDate} type="date" onChange={(e)=>setnextInterviewDate(e.target.value)}/>
               </div>
               <hr className='mt-50 mb-50'/>
        <h2 className='mb-50'>HR ROUND</h2>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                  <label class='col-6'>HR Round Feedback</label>
                  <input class='col-6' placeholder={thirdRoundStatus} type="text" onChange={(e)=>setthirdRoundStatus(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                  <label class='col-6'>Salary</label>
                  <input class='col-6' placeholder={salaryAmount} type="text" onChange={(e)=>setsalaryAmount(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
               <label class='col-6'>Joining Date</label>
               <input class='col-6' value={joiningDate} type="date" onChange={(e)=>setjoiningDate(e.target.value)}/>
            </div>   
       <div class='mt-50' style={{marginLeft:'600px'}}>
        <button onClick={(e)=>updatevacancy(e)} class='btn btn-primary'>SAVE</button>
       </div>
       </form>
       </div>
    </div>
  </div>
</div>
<div class="row">
     <div class="col-lg-12" id="images">
  </div>
</div>
    </div>	
    <Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>	   */}
</Helmet>

</div>

  )
}
