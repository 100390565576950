export default function Quickpanel(){
    return(
        // <!-- quick_panel_toggle -->
        <div class="modal modal-right fade" id="quick_panel_toggle" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content slim-scroll2">
                <div class="modal-body bg-white py-20 px-0">
                  <div class="d-flex align-items-center justify-content-between pb-30">
                      <ul class="nav nav-tabs customtab3 px-30" role="tablist">
                          <li class="nav-item">
                              <a class="nav-link active" data-bs-toggle="tab" href="#quick_panel_logs">Audit Logs</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" data-bs-toggle="tab" href="#quick_panel_notifications">Notifications</a>
                          </li>
                          
                      </ul>
                      <div class="offcanvas-close">
                          <a href="#" class="btn btn-icon btn-danger-light btn-sm no-shadow" data-bs-dismiss="modal">
                              <span class="fa fa-close"></span>
                          </a>
                      </div>
                  </div>
                    <div class="px-30">
                      <div class="tab-content">
                          <div class="tab-pane active" id="quick_panel_logs" role="tabpanel">
                              <div class="mb-30">
                                  <h5 class="fw-500 mb-15">System Messages</h5>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-lightest h-50 w-50 l-h-50 rounded text-center">
                                            <img src="images/svg-icon/color-svg/001-glass.svg" class="h-30" alt=""/>
                                      </div>
                                      <div class="d-flex flex-column flex-grow-1 me-2 fw-500">
                                          <a href="#" class="text-dark hover-primary mb-1 fs-16">Duis faucibus lorem</a>
                                          <span class="text-fade">Pharetra, Nulla</span>
                                      </div>
                                      <span class="badge badge-xl badge-light"><span class="fw-600">+125$</span></span>
                                  </div>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-lightest h-50 w-50 l-h-50 rounded text-center">
                                            <img src="images/svg-icon/color-svg/002-google.svg" class="h-30" alt=""/>
                                      </div>
                                      <div class="d-flex flex-column flex-grow-1 me-2 fw-500">
                                          <a href="#" class="text-dark hover-danger mb-1 fs-16">Mauris varius augue</a>
                                          <span class="text-fade">Pharetra, Nulla</span>
                                      </div>
                                      <span class="badge badge-xl badge-light"><span class="fw-600">+125$</span></span>
                                  </div>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-lightest h-50 w-50 l-h-50 rounded text-center">
                                            <img src="images/svg-icon/color-svg/003-settings.svg" class="h-30" alt=""/>
                                      </div>
                                      <div class="d-flex flex-column flex-grow-1 me-2 fw-500">
                                          <a href="#" class="text-dark hover-success mb-1 fs-16">Aliquam in magna</a>
                                          <span class="text-fade">Pharetra, Nulla</span>
                                      </div>
                                      <span class="badge badge-xl badge-light"><span class="fw-600">+125$</span></span>
                                  </div>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-lightest h-50 w-50 l-h-50 rounded text-center">
                                            <img src="images/svg-icon/color-svg/004-dad.svg" class="h-30" alt=""/>
                                      </div>
                                      <div class="d-flex flex-column flex-grow-1 me-2 fw-500">
                                          <a href="#" class="text-dark hover-info mb-1 fs-16">Phasellus venenatis nisi</a>
                                          <span class="text-fade">Pharetra, Nulla</span>
                                      </div>
                                      <span class="badge badge-xl badge-light"><span class="fw-600">+125$</span></span>
                                  </div>
                                  <div class="d-flex align-items-center">
                                      <div class="me-15 bg-lightest h-50 w-50 l-h-50 rounded text-center">
                                            <img src="images/svg-icon/color-svg/005-paint-palette.svg" class="h-30" alt=""/>
                                      </div>
                                      <div class="d-flex flex-column flex-grow-1 me-2 fw-500">
                                          <a href="#" class="text-dark hover-warning mb-1 fs-16">Vivamus consectetur</a>
                                          <span class="text-fade">Pharetra, Nulla</span>
                                      </div>
                                      <span class="badge badge-xl badge-light"><span class="fw-600">+125$</span></span>
                                  </div>
                              </div>
                              <div class="mb-30">
                                  <h5 class="fw-500 mb-15">Tasks Overview</h5>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-primary-light h-50 w-50 l-h-60 rounded text-center">
                                          <i class="fa-solid fa-shield"></i>
                                      </div>
                                      <div class="d-flex flex-column fw-500">
                                          <a href="#" class="text-dark hover-primary mb-1 fs-16">Project Briefing</a>
                                          <span class="text-fade">Project Manager</span>
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-danger-light h-50 w-50 l-h-60 rounded text-center">
                                          <i class="fa-solid fa-pen"></i>
                                      </div>
                                      <div class="d-flex flex-column fw-500">
                                          <a href="#" class="text-dark hover-danger mb-1 fs-16">Concept Design</a>
                                          <span class="text-fade">Art Director</span>
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-success-light h-50 w-50 l-h-60 rounded text-center">
                                          <i class="fa-solid fa-boxes-stacked"></i>
                                      </div>
                                      <div class="d-flex flex-column fw-500">
                                          <a href="#" class="text-dark hover-success mb-1 fs-16">Functional Logics</a>
                                          <span class="text-fade">Lead Developer</span>
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center mb-30">
                                      <div class="me-15 bg-info-light h-50 w-50 l-h-60 rounded text-center">
                                          <i class="fa-solid fa-code"></i>
                                      </div>
                                      <div class="d-flex flex-column fw-500">
                                          <a href="#" class="text-dark hover-info mb-1 fs-16">Development</a>
                                          <span class="text-fade">DevOps</span>
                                      </div>
                                  </div>
                                  
                              </div>
                          </div>
                          <div class="tab-pane" id="quick_panel_notifications" role="tabpanel">
                              <div>
                                  <div class="media-list">
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">10:10</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-primary">
                                          <p>Morbi quis ex eu arcu auctor sagittis.</p>
                                          <span class="text-fade">by Johne</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">08:40</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-success">
                                          <p>Proin iaculis eros non odio ornare efficitur.</p>
                                          <span class="text-fade">by Amla</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">07:10</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-info">
                                          <p>In mattis mi ut posuere consectetur.</p>
                                          <span class="text-fade">by Josef</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">01:15</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-danger">
                                          <p>Morbi quis ex eu arcu auctor sagittis.</p>
                                          <span class="text-fade">by Rima</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">23:12</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-warning">
                                          <p>Morbi quis ex eu arcu auctor sagittis.</p>
                                          <span class="text-fade">by Alaxa</span>
                                        </div>
                                      </a>
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">10:10</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-primary">
                                          <p>Morbi quis ex eu arcu auctor sagittis.</p>
                                          <span class="text-fade">by Johne</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">08:40</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-success">
                                          <p>Proin iaculis eros non odio ornare efficitur.</p>
                                          <span class="text-fade">by Amla</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">07:10</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-info">
                                          <p>In mattis mi ut posuere consectetur.</p>
                                          <span class="text-fade">by Josef</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">01:15</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-danger">
                                          <p>Morbi quis ex eu arcu auctor sagittis.</p>
                                          <span class="text-fade">by Rima</span>
                                        </div>
                                      </a>
      
                                      <a class="media media-single px-0" href="#">
                                        <h4 class="w-50 text-gray fw-500">23:12</h4>
                                        <div class="media-body ps-15 bs-5 rounded border-warning">
                                          <p>Morbi quis ex eu arcu auctor sagittis.</p>
                                          <span class="text-fade">by Alaxa</span>
                                        </div>
                                      </a>
                                    </div>
                              </div>
                          </div>
                          
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        
        )
}