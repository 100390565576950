import React from 'react'
import { Helmet } from 'react-helmet'

export default function Footer() {
  return (
    <div>
        <footer class="main-footer">
    <div class="pull-right d-none d-sm-inline-block">
        <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
		  <li class="nav-item">
			    <ul><img src="assets/images/icons/facebook.png" alt="" class="addition_footer_img"/></ul>
                <ul><img src="assets/images/icons/instagram.png" alt="" class="addition_footer_img"/></ul>
                <ul><img src="assets/images/icons/linkedin.png" alt="" class="addition_footer_img"/></ul>
                <ul><img src="assets/images/icons/twitter.png" alt="" class="addition_footer_img"/></ul>
		  </li>
		</ul>
    </div>
    
  </footer>
  {/* <!-- Side panel -->  */}
  {/* <!-- /.content-wrapper --> */}
  <footer class="main-footer">
    <div class="pull-right d-none d-sm-inline-block">
        <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
		  <li class="nav-item">
			<a class="nav-link" href="#">

Purchase Now

© 2022 HRMS Themes. All Rights Reserved. </a>
		  </li>
		</ul>
    </div>
	   
      
  </footer>
    </div>
  )
}
