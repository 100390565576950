import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useLocation } from 'react-router-dom';

export default function ViewCandidate() {
    const token = localStorage.getItem('token')
    const state = useLocation()
    const [candidate, setcandidate] = useState('')
    const nav = useNavigate()
console.log(state);
    const getCandidate = async () => {
        axios({
               'method':'GET',
               'url':'https://node.firehrm.com/HRMS/HRMS/admin/getById-interview/'+state?.state?._id
               
           }).then((response) => {
               console.log(response)
                setcandidate(response?.data?.data?.[0])
           })
           .catch((error) =>{
               console.log(error)
           })
       }
   
       useEffect(() => {getCandidate();},[]);

function navtoupdate(e,x){
    nav('/updateCandidate',{state:x})
}

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="col-11 ms-50">

<div class="box p-50">
   <div class="box-header with-border">
     <h3 class="box-title" style={{marginRight:"700px"}}>CANDIDATE</h3>
     <button onClick={(e)=>navtoupdate(e,candidate)} class='btn btn-success' style={{marginLeft:'130px',paddingRight:'25px',paddingLeft:'25px'}}>UPDATE</button>
   </div>
   {/* <!-- /.box-header --> */}
   <div class="box-body">
   <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
        
           <thead>
                <tr>
                   <th>Name</th>
                   <td>{candidate?.screening?.candidateName}</td>
               </tr>
               <tr>
                   <th>Description</th>
                   <td>{candidate?.screening?.desc}</td>
               </tr>
               <tr>
                   <th>Resume</th>
                   <td><iframe src={candidate?.screening?.resumeUpload} frameborder="0"></iframe></td>
               </tr>
               {candidate?.TechnicalInterview?.secondRoundStatus=="notCleared"?
               <>
               <tr>
                   <th>Status</th>
                   <td>{candidate?.screening?.firstRoundStatus}</td>
               </tr>
               <tr>
                   <th>Technical Interview Schedule</th>
                   <td>{candidate?.screening?.nextInterviewSchedule}</td>
               </tr>
               </>:
               <>
                <tr><h5>Technical Interview</h5></tr>
                <tr>
                    <th>Feedback</th>
                    <td>{candidate?.TechnicalInterview?.feedBack}</td>
                </tr>
                <tr>
                    <th>HR Interview Schedule</th>
                    <td>{candidate?.TechnicalInterview?.nextInterviewDate}</td>
                </tr>
                {candidate?.HRInterview?.thirdRoundStatus=="notCleared"?
                <>
                <tr><h5>Joining Date</h5></tr>
                <tr>
                    <th>Joining Date</th>
                    <td>{candidate?.HRInterview?.joiningDate}</td>
                </tr>
                <tr>
                    <th>Salary</th>
                    <td>{candidate?.HRInterview?.salaryAmount}</td>
                </tr>
              </>:
              ''
               }
               </>
               }
           </thead>

       </table>
       <div style={{marginLeft:'1200px'}}>
       </div>
       </div>
       
   </div>
  
 </div>

</div>

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
