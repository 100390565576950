import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function UserSideBar() {
  const id = localStorage.getItem('id')
  const [EmployeeData, setEmployeeData] = useState('');
  const loginId = localStorage.getItem('loginId')
  const [InOutData, setInOutData] = useState('');
  const token = localStorage.getItem('token')
  const nav = useNavigate()

  const employeeDetails = async () => {
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/employee/getById/'+ id
    })
    .then((response) => {
        console.log(response)
        setEmployeeData(response?.data?.data?.[0])
        console.log(EmployeeData)
    })
    .catch((error) => {
        console.log(error)
    }
    )
}
useEffect(() =>{employeeDetails();},[]);

const [organizationData, setorganizationData] = useState({});

console.log(organizationData)
const organizationDetails = async () => {
    // const token = localStorage.getItem('token');
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/admin/getAll'   
    })
    .then((responce) => {
        console.log(responce)
        setorganizationData(responce?.data?.message?.[0])
      
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {
    organizationDetails();
}, []);

const LoginLogoutDetails = async () => {
  axios({
      'method':'GET',
      'url': 'https://node.firehrm.com/HRMS/HRMS/employee/OurAttendence',
      'headers':{
        "Authorization":token
      }
  })
  .then((response) => {
      console.log(response)
       setInOutData(response?.data?.data)
       console.log(InOutData)
  })
  .catch((error) => {
      console.log(error)
  }
  )
}
useEffect(() =>{LoginLogoutDetails();},[]);


  function Logout(e){
    e.preventDefault()
    axios({
        'method':'PUT',
        'url': 'https://node.firehrm.com/HRMS/HRMS/employee/logout/'+ loginId
    })
    .then((response) => {
        console.log(response)
        if(response.status == '200'){
          localStorage.clear()
          nav('/')
          window.location.reload()
        }
    })
    .catch((error) => {
        console.log(error)
    }
    )
}
function LogoutAdmin(e){
  e.preventDefault()
  localStorage.clear()
  nav('/')
  window.location.reload()
}
  return (
    // <!-- quick_user_toggle -->
  <div class="modal modal-right fade" id="quick_user_toggle" tabindex="-1">
	  <div class="modal-dialog">
		<div class="modal-content slim-scroll3">
		  <div class="modal-body p-30 bg-white">
			<div class="d-flex align-items-center justify-content-between pb-30">
				<h4 class="m-0">{EmployeeData?.name}</h4>
				{/* <small class="text-fade fs-12 ms-5"12 messages</small>
        </h4> */}
				<a href="#" class="btn btn-icon btn-danger-light btn-sm no-shadow" data-bs-dismiss="modal">
					<span class="fa fa-close"></span>
				</a>
			</div>
            <div>
            {localStorage.getItem('role')=='employee'?
              <div class="d-flex flex-row">
                    <div class=""><img src={EmployeeData?.profileImage} alt="user" class="rounded bg-danger-light w-150" width="100"/></div>
                    <div class="ps-20">
                        <h5 class="mb-0">
                          {/* {EmployeeData} */}
                          </h5>
                        <p class="my-5 text-fade">{EmployeeData?.designation?.designationName}</p>
                        <a href="mailto:dummy@gmail.com"><i class="fa-solid fa-envelope my-5"></i>{EmployeeData?.email}</a>
                        <button onClick={(e)=>Logout(e)} class="btn btn-danger-light btn-sm mt-20"><i class="ti-minus"></i> Logout</button>
                    </div>
                </div>:
                <div class="d-flex flex-row">
                    <div class=""><img src={organizationData?.companyLogo} alt="user" class="rounded bg-danger-light w-150" width="100"/></div>
                    <div class="ps-20">
                        <h5 class="mb-0">
                          {/* {EmployeeData} */}
                          </h5>
                        <p class="my-5 text-fade">ADMIN</p>
                        <a href="mailto:dummy@gmail.com"><i class="fa-solid fa-envelope my-5"></i>{organizationData?.email}</a>
                        {!organizationData?
                        <button onClick={(e)=>Logout(e)} class="btn btn-danger-light btn-sm mt-20"><i class="ti-minus"></i> Logout</button>
                        :<button onClick={(e)=>LogoutAdmin(e)} class="btn btn-danger-light btn-sm mt-20"><i class="ti-minus"></i> Logout</button>
                        }
                    </div>
                </div>}
			</div>
              {/* <div class="dropdown-divider my-30"></div> */}
              {/* <div>
                <div class="d-flex align-items-center mb-30"> */}
                    {/* <div class="me-15 bg-primary-light h-50 w-50 l-h-60 rounded text-center">
						            <i class="fa-solid fa-user"></i>
                    </div> */}
                    {/* <div class="d-flex flex-column fw-500">
                        <a href="#" class="text-dark hover-primary mb-1 fs-16">My Profile</a>
                        <span class="text-fade">Account settings and more</span>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-30">
                    <div class="me-15 bg-danger-light h-50 w-50 l-h-60 rounded text-center">
                        <i class="fa-solid fa-message"></i>
                    </div>
                    <div class="d-flex flex-column fw-500">
                        <a href="#" class="text-dark hover-danger mb-1 fs-16">My Messages</a>
                        <span class="text-fade">Inbox and tasks</span>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-30">
                    <div class="me-15 bg-success-light h-50 w-50 l-h-60 rounded text-center">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                    <div class="d-flex flex-column fw-500">
                        <a href="#" class="text-dark hover-success mb-1 fs-16">My Activities</a>
                        <span class="text-fade">Logs and notifications</span>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-30">
                    <div class="me-15 bg-info-light h-50 w-50 l-h-60 rounded text-center">
                        <i class="fa-solid fa-list-check"></i>
                    </div>
                    <div class="d-flex flex-column fw-500">
                        <a href="#" class="text-dark hover-info mb-1 fs-16">My Tasks</a>
                        <span class="text-fade">latest tasks and projects</span>
                    </div> */}
                {/* </div>
              </div> */}
              <div class="dropdown-divider my-30"></div>
              <div>
                {InOutData && InOutData.reverse().map((x=>
                <div class="media-list">
                    {/* <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">10:10</h4>
                      <div class="media-body ps-15 bs-5 rounded border-primary">
                        <p>Morbi quis ex eu arcu auctor sagittis.</p>
                        <span class="text-fade">by Johne</span>
                      </div>
                    </a> */}
       
                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">LOGIN</h4>
                      <div class="media-body ps-15 bs-5 rounded border-success">
                        <p>{x?.loginTime}</p>
                        <span class="text-fade">{x?.loginDate}</span>
                      </div>
                    </a>

                    {/* <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">07:10</h4>
                      <div class="media-body ps-15 bs-5 rounded border-info">
                        <p>In mattis mi ut posuere consectetur.</p>
                        <span class="text-fade">by Josef</span>
                      </div>
                    </a> */}

                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">LOGOUT</h4>
                      <div class="media-body ps-15 bs-5 rounded border-danger">
                        <p>{x?.logoutTime == '0'? "LOGED-IN" : x?.logoutTime}</p>
                        <span class="text-fade">{x?.loginDate}</span>
                      </div>
                    </a>

                    {/* <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">23:12</h4>
                      <div class="media-body ps-15 bs-5 rounded border-warning">
                        <p>Morbi quis ex eu arcu auctor sagittis.</p>
                        <span class="text-fade">by Alaxa</span>
                      </div>
                    </a>
                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">10:10</h4>
                      <div class="media-body ps-15 bs-5 rounded border-primary">
                        <p>Morbi quis ex eu arcu auctor sagittis.</p>
                        <span class="text-fade">by Johne</span>
                      </div>
                    </a>

                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">08:40</h4>
                      <div class="media-body ps-15 bs-5 rounded border-success">
                        <p>Proin iaculis eros non odio ornare efficitur.</p>
                        <span class="text-fade">by Amla</span>
                      </div>
                    </a>

                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">07:10</h4>
                      <div class="media-body ps-15 bs-5 rounded border-info">
                        <p>In mattis mi ut posuere consectetur.</p>
                        <span class="text-fade">by Josef</span>
                      </div>
                    </a>

                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">01:15</h4>
                      <div class="media-body ps-15 bs-5 rounded border-danger">
                        <p>Morbi quis ex eu arcu auctor sagittis.</p>
                        <span class="text-fade">by Rima</span>
                      </div>
                    </a>

                    <a class="media media-single px-0" href="#">
                      <h4 class="w-50 text-gray fw-500">23:12</h4>
                      <div class="media-body ps-15 bs-5 rounded border-warning">
                        <p>Morbi quis ex eu arcu auctor sagittis.</p>
                        <span class="text-fade">by Alaxa</span>
                      </div>
                    </a> */}
                  </div>
                  ))}
            </div>
		  </div>
		</div>
	  </div>
      
 
  {/* <!-- /quick_user_toggle --> */}
  </div>
  )
}
