import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function Getallleaveapplication() {
    const [LeaveApplication, setAllLeaveApplication] = useState([]);
    const token = localStorage.getItem('token')
    const nav = useNavigate()
    const AllLeaveApplication = async () => {
        axios({
            'method':'GET',
            'url':'https://node.firehrm.com/HRMS/HRMS/employee/getAll-leaveList',
            'headers':{
                'Authorization':token 
            }
        })
        .then((response) =>{
            console.log(response)
            setAllLeaveApplication(response.data.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    useEffect(() => {AllLeaveApplication();},[]);

    function ViewSingleLeaveApplication(e,x){
      nav('/adminViewsingleLeave',{state:x})
    }

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>
<div class="col-11 ms-50">

<div class="box p-50">
   <div class="box-header with-border">
     <h3 class="box-title">Leavelist</h3>
   </div>
   {/* <!-- /.box-header --> */}
   <div class="box-body">
       <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
           <thead>
               <tr>
                   <th>Name</th>
                   <th>Position</th>
                   <th>From Date</th>
                   <th>To Date</th>
                   <th>Subject</th>
                   <th>Action</th>
               </tr>
           </thead>
           
           <tbody>
            {LeaveApplication && LeaveApplication.reverse().map(x=>
               <tr>
                   <td>{x?.employeeDetails?.name}</td>
                   <td>{x?.employeeDetails?.designation?.designationName}</td>
                   <td>{x?.fromDate}</td>
                   <td>{x?.toDate}</td>
                   <td>{x?.reason}</td>
                   <td><button onClick={(e)=>ViewSingleLeaveApplication(e,x)} className="bg-primary" ><i class="fa fa-eye" aria-hidden="true"></i></button></td>
               </tr>
            )}
               
           </tbody>
           {/* <tfoot>
               <tr>
                   <th>Name</th>
                   <th>Position</th>
                   <th>Office</th>
                   <th>Age</th>
                   <th>Start date</th>
                   <th>Salary</th>
               </tr>
           </tfoot> */}
       </table>
       </div>
   </div>
   {/* <!-- /.box-body --> */}
 </div>
 {/* <!-- /.box -->       */}
</div>
<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
