import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

export default function UpdateOrganization() {

    const updateOrgDetails = useNavigate()
    const { state } = useLocation();
    console.log(state)
    const [officeImage, setOfficeImage] = useState();
    const [officeImage1, setOfficeImage1] = useState([]);
    const [officelogo, setOfficelogo] = useState();
    const [officelogo1, setOfficelogo1] = useState([]);
    const [officeAchievements, setOfficeAchievements] = useState();
    const [officeAchievements1, setofficeAchievements1] = useState([]);
    const [companyName, setCompanyName] = useState(state?.companyName);
    const [email, setEmail] = useState(state?.email);
    const [contact, setContactNumber] = useState(state?.contact);
    const [websiteLink, setWebsiteLink] = useState(state?.websiteLink);
    const [about, setAbout] = useState(state?.about);
    const [CEO, setCEO] = useState(state?.CEO);
    const [founders, setFounder] = useState(state?.founders);
    const [foundedOn, setFoundedOn] = useState(state?.foundedOn);
    const [headQuarters, setHeadquarters] = useState(state?.headQuarters);
    const [revenue, setRevenue] = useState(state?.revenue);
    const [numberOfEmployees, setNumberOfEmployees] = useState(state?.numberOfEmployees);
    const [subsidiaries, setSubsidiaries] = useState(state?.subsidiaries);

    async function UpdateOrganization() {
        updateOrganizationData()
        // let initial = await ((await UploadImage()).json())
        // console.log(initial.data);
        // setOfficeImage(initial.data.image)

        // if (initial.data !== null || initial.data !== undefined) {
        //     console.log(
        //         "helloo"
        //     );
        // } else {
        //     console.log("ther is an error");
        // }
        // console.log('hiiiii');

    }

    // e.preventDefault();
    const updateData = { companyName, websiteLink, numberOfEmployees, CEO, officeImage, email, contact, about, founders, foundedOn, headQuarters, revenue, numberOfEmployees, subsidiaries }
    const id = localStorage.getItem('ID')
    function updateOrganizationData() {

        setTimeout(() => {
            fetch('https://node.firehrm.com/HRMS/HRMS/admin/update/' + state._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ officeAchievements, officelogo, companyName, websiteLink, numberOfEmployees, CEO, officeImage, email, contact, about, founders, foundedOn, headQuarters, revenue, numberOfEmployees, subsidiaries })
            }).then((res) => {
                console.log(res);
                // updateOrganizationData = updateOrganizationData.json()
                // console.log(updateOrganizationData)
                if (res.status == '200') { updateOrgDetails('/organizationDetails') }
            })
                .catch((err) => {
                    console.log(err);
                })


        })
    }

    async function UploadImage() {
            const formdata1 = new FormData()
            formdata1.append('image', officeImage1)
            var resp = await fetch('https://node.firehrm.com/HRMS/HRMS/admin/image', {
                method: 'POST',
                body: formdata1
            }).then((response) => {
                return response.json()
            }).then((resp) => {
                console.log(resp);
                setOfficeImage(resp.data.image);
            })
                .catch((error) => {
                    console.log(error)
                })
        }
        // useEffect(() =>  {
        //     UploadImage();
        // }, [officeImage1]);
        //     return resp;
        // } catch (e) {
        //     console.log(e);
        // }

    async function UploadLogo() {
        const formdata2 = new FormData()
       formdata2.append('image', officelogo1)
       var resp = await fetch('https://node.firehrm.com/HRMS/HRMS/admin/image', {
            method: 'POST',
            body: formdata2
        }).then((response) => {
            return response.json()
        }).then((resp) => {
            console.log(resp);
            setOfficelogo(resp.data.image);
        })
            .catch((error) => {
                console.log(error)
            })
    }

    // useEffect(() =>  {
    //     UploadLogo();
    // }, [officelogo1]);

    async function UploadAchievements() {
        const formdata3 = new FormData()
        console.log(officeAchievements1)
        formdata3.append('image', officeAchievements1)
       var resp = await fetch('https://node.firehrm.com/HRMS/HRMS/admin/image', {
            method: 'POST',
            body: formdata3
        }).then((response) => {
            return response.json()
        }).then((resp) => {
            console.log(resp);
            setOfficeAchievements(resp.data.image);
        })
            .catch((error) => {
                console.log(error)
            })
    }
// useEffect(() =>  {
//     UploadAchievements();
//     }, [officeAchievements1]);

    return (
        <div class="layout-top-nav light-skin theme-primary fixed">

            <div class="wrapper mt-150 ms-50 me-50">
                <Header />

                <UserSideBar />

                <QuickActionToggle />

                <div class="row">

                    <div class="col-lg-12">

                        <div class="panel panel-primary">
                            <div class="panel-heading">
                                <h3 class="panel-title"><strong>UPDATE ORGANIZATION</strong></h3>
                            </div>
                            <div class="panel-body p-50">
                                <form>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>

                                        <label class='col-6'>Company Logo</label>
                                        <input class='col-5' type="file" onChange={(e) => setOfficelogo1(e.target.files[0])} />
                                        <button className='col-1 btn btn-primary px-100x' style={{backGround:' #A020F0'}} onClick={UploadLogo}>Upload</button>
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Company Name</label>
                                        <input class='col-6' value={companyName} onChange={(e) => setCompanyName(e.target.value)} type="text" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Office Image</label>
                                        <input class='col-5' type="file" onChange={(e) => setOfficeImage1(e.target.files[0])} />
                                        <button className='col-1 btn btn-primary px-100x' style={{backGround:' #A020F0'}} onClick={UploadImage}>Upload</button>
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Email</label>
                                        <input class='col-6' value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Contact</label>
                                        <input class='col-6' value={contact} onChange={(e) => setContactNumber(e.target.value)} type="tel" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>About</label>
                                        <textarea class='col-6' value={about} onChange={(e) => setAbout(e.target.value)} name="" id="" cols="30" rows="10"></textarea>
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Web Address</label>
                                        <input class='col-6' value={websiteLink} onChange={(e) => setWebsiteLink(e.target.value)} type="text" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>CEO</label>
                                        <input class='col-6' value={CEO} onChange={(e) => setCEO(e.target.value)} type="text" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Founded On</label>
                                        <input value={foundedOn} class='col-6' onChange={(e) => setFoundedOn(e.target.value)} type="date" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Head Quarters</label>
                                        <input class='col-6' value={headQuarters} onChange={(e) => setHeadquarters(e.target.value)} type="text" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Revenue</label>
                                        <input value={revenue} class='col-6' onChange={(e) => setRevenue(e.target.value)} type="tel" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Number Of Employees</label>
                                        <input class='col-6' value={numberOfEmployees} onChange={(e) => setNumberOfEmployees(e.target.value)} type="tel" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Founders</label>
                                        <input value={founders} class='col-6' onChange={(e) => setFounder(e.target.value)} type="text" />
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Achievements</label>
                                        <input type="file" class='col-5' onChange={(e) => setofficeAchievements1(e.target.files[0])} />
                                        <button className='col-1 btn btn-primary px-100x' style={{backGround:' #A020F0'}} onClick={UploadAchievements}>Upload</button>
                                    </div>
                                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                                        <label class='col-6'>Subsidiaries</label>
                                        <input value={subsidiaries} class='col-6' onChange={(e) => setSubsidiaries(e.target.value)} type="text" />
                                    </div>

                                    {/* <tbody>
            {/* {LeaveApplication && LeaveApplication.reverse().map(x=> */}
                                    {/* <tr>
                   <td>{}</td>
                   <td>{}</td>
               </tr>
            {/* )} */}

                                    {/* </tbody>  */}
                                    {/* <tfoot>
               <tr>
                   <th>Name</th>
                   <th>Position</th>
                   <th>Office</th>
                   <th>Age</th>
                   <th>Start date</th>
                   <th>Salary</th>
               </tr>
           </tfoot> */}

                                    <div class="mt-4" style={{ marginLeft: '500px' }}>
                                        <input type="button" onClick={UpdateOrganization} class="btn btn-primary px-100" value="SAVE" />
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12" id="images">
                    </div>
                </div>
            </div>
            <Footer />

            <Helmet>    	{/* <!-- Vendor JS --> */}
                <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

                <script src="assets/js/vendors.min.js"></script>
                <script src="assets/js/pages/chat-popup.js"></script>
                <script src="assets/icons/feather-icons/feather.min.js"></script>

                <script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

                {/* <!-- HRMS App --> */}
                <script src="assets/js/jquery.smartmenus.js"></script>
                <script src="assets/js/menus.js"></script>
                <script src="assets/js/template.js"></script>
                <script src="assets/js/pages/dashboard.js"></script>
                <script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
                <script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
                <script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
                {/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>	   */}
            </Helmet>

        </div>
    )
}
