import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet'

export default function CreateSAP() {

    const navToDocumentWallet = useNavigate()
    const employeeName = localStorage.getItem('name')
    const email = localStorage.getItem('email')
    const [message, setMessage] = useState('')
    const SAP = {employeeName,email,message}
    const token = localStorage.getItem('token')

    function createsap(e){
    axios({
        'method': 'POST',
        'url':'https://node.firehrm.com/HRMS/HRMS/employee/create-suggestion',
        'headers':{
            'Authorization':token
        },
        'data': SAP

    }).then((resp)=>{
        console.log(resp);
        if(resp.status == '200'){navToDocumentWallet('/index')}
    } )
    .catch((error)=>{
        console.log(error)
        })
    }

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150 ms-50 me-50">
        <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="row">

  <div class="col-lg-12">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><strong>WRITE FEEDBACK</strong></h3>
      </div>
      <div class="panel-body p-50">
<form>
					<div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
							<label class='col-6'>Name</label>
							<h5 class='col-6' type="text"  placeholder="Text input">{employeeName}</h5>
						</div>
					<div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
							<label class='col-6'>Email</label>
							<h5 class='col-6'type="password" placeholder="Password input">{email}</h5>
						</div>
					<div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
							<label class='col-6'>Message</label>
							<textarea class='col-6' onChange={(e)=>setMessage(e.target.value)} rows="5" cols="5"></textarea>
						</div>
                        <div className='mt-50' style={{marginLeft:'600px'}}>
                            <button onClick={(e)=>createsap(e)} class='btn btn-primary'>SEND</button>
                        </div>
                        </form>
    </div>
    </div>
  </div>
</div>
<div class="row">
     <div class="col-lg-12" id="images">
  </div>
</div>
    </div>	
    <Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>	   */}
</Helmet>

</div>
  )
}
