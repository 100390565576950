import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Nav() {
  return (
    <nav class="main-nav mt-0" role="navigation">
        <label class="main-menu-btn" htmlFor="main-menu-state">
        </label>
        <ul id="main-menu" class="sm sm-blue" data-smartmenus-id="16566785059383533"> 
        {localStorage.getItem('role')=='admin'?
        <li><NavLink activeclass="activenav" to="/loginAndLogout"><i class="fa-brands fa-buffer"></i>Attendance</NavLink></li>:""}

        <li><NavLink activeclass="activenav" to="/organizationDetails"><i class="fa-solid fa-people-group"></i>Organization</NavLink>
        </li>

        {localStorage.getItem("role")=='admin'?<li><NavLink activeclass="activenav" to="/getallleaveapplication"><i class="fa-solid fa-file-invoice"></i>Leave Application</NavLink></li>
        :<li><NavLink activeclass="activenav" to="/leaveApplicationHistory"><i class="fa-solid fa-file-invoice"></i>Leave Application</NavLink></li>}

        {localStorage.getItem('role')=='admin'?<li><NavLink activeclass="activenav" to="/viewAllPayslip"><i class="fa-solid fa-ticket"></i>Payslip</NavLink></li>:
        <li><NavLink activeclass="activenav" to="/monthlyPayslipForEmployee"><i class="fa-solid fa-ticket"></i>Payslip</NavLink></li>}

        {localStorage.getItem('role')=='employee'?<li><NavLink activeclass="activenav" to="/employeeDocumentWallet"><i class="fa-solid fa-folder"></i>Document Wallet</NavLink></li>:<li><NavLink activeclass="activenav" to="/documentWalletList"><i class="fa-solid fa-folder"></i>Document Wallet</NavLink></li>}

        {localStorage.getItem('role')=='admin'?<li><NavLink activeclass="activenav" to="/createCarosel"><i class="fa-solid fa-list"></i>Carousel</NavLink></li>:''}

        {localStorage.getItem("role")=='admin'?<li><NavLink activeclass="activenav" to="/getAllOpenings"><i class="fa-solid fa-address-book"></i>Interview Process</NavLink></li>:''}

        {localStorage.getItem('role')=="employee"?<li><NavLink activeclass="activenav" to="/viewEmployeeProfile"><i class="fa-solid fa-address-book"></i>Profile</NavLink></li>
        :<>
         <li><a href="viewAllEmployee"><i class="fa-solid fa-id-card-clip"></i>Employee Profile</a>
          </li>
        </>
        }
        {localStorage.getItem('role')=='admin'?<li><NavLink activeclass="activenav" to="/viewSAP"><i class="fa-solid fa-blog"></i>Feedback</NavLink></li>:<li><NavLink activeclass="activenav" to="/createSAP"><i class="fa-solid fa-blog"></i>Feedback</NavLink></li>}
        
    </ul>
        
      </nav>
  )
}
