import React,{useState} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function CreateLeaveApplication() {

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  console.log(fromDate);
  const [fullOrHalfDay, setDay] = useState('');
  const [typeOfLeave, setTypeOfLeave] = useState('');
  const [reason, setReason] = useState('');
  const [reasonInDetails, setMessage] = useState('');
  const [uploadDocument, setimg] = useState('');
  const [uploadDocument1, setDoc1] = useState([]);
  const nav = useNavigate();
  const formdata = new FormData()
//   formdata.append('typeOfLeave',typeOfLeave)
//   formdata.append('fromDate',fromDate)
//   formdata.append('toDate',toDate)
//   formdata.append('fullOrHalfDay',fullOrHalfDay)
//   formdata.append('reason',reason)
//   formdata.append('reasonInDetails',reasonInDetails)
//   formdata.append('uploadDocument',uploadDocument)
  console.log(formdata)
  const SubmitLeaveRequest = async (e) => {
    addImg(e)
      e.preventDefault();
      const token = localStorage.getItem('token');
      let leaveApplication = await fetch('https://node.firehrm.com/HRMS/HRMS/employee/create-leave',{
          method: 'POST',
          headers:{
              'Authorization' : token, 
              'Content-type':'application/json'
          },
          body: JSON.stringify({
            fromDate,
            toDate,
            fullOrHalfDay,
            typeOfLeave,
            reason,
            reasonInDetails,
            uploadDocument
        })
      
      })
   .then((response)=>{
      console.log(response)
      if(response.status=='200'){
    // nav('/leaveApplicationHistory')
      }
  }).catch((error)=>{
      console.log(error)
  })

  function addImg(e){
    e.preventDefault();
    const formdata1 = new FormData();
    //  for(var i = 0;i < image.length ; i++)
    formdata1.append('image',uploadDocument1[0]);
   axios({
    'method': 'POST',
    'url':'https://node.firehrm.com/HRMS/HRMS/admin/image',
    'data':formdata1
    }).then((response)=>{
        console.log(response)
        setimg(response?.data?.data?.image)
        
    })
    .catch((error)=>{
        console.log(error)
    })
}

  }

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150 ms-50 me-50">
        <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="row">

  <div class="col-lg-12">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><strong>APPLY LEAVE</strong></h3>
      </div>
      <div class="panel-body p-50">
<form>
<div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                            <label class='col-6' htmlFor="from_date">From</label>
                            <input class="col-6 " type="date" name="from_date" id="from_date" 
                             onChange={(e) => setFromDate(e.target.value)}
                            />
                        </div>
                        <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                            <label class='col-6' htmlFor="to_date">To</label>
                            <input class="col-6" type="date" name="to_date" id="to_date" 
                             onChange={(e) => setToDate(e.target.value)}
                            />
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                    <label class='col-6' htmlFor="to_date">Full/Half Day</label>
                            <select class='col-6' aria-label="Default select example" 
                             onChange={(e) => setDay(e.target.value)} 
                            >
                                    <option selected>Full/Half Day</option>
                                    <option value="Full Day">Full Day</option>
                                    <option value="Half Day">Half Day</option>
                            </select>
                    </div>
                        
                        {/* <div class="row mt-4 ms-3"> */}
                        <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class='col-6' htmlFor="to_date">Leave Options</label>
                            <select class="col-6" aria-label="Default select example" 
                             onChange={(e) => setTypeOfLeave(e.target.value)} 
                            >
                                    <option selected>Leave Options</option>
                                    <option value="casualLeave">Casual Leave</option>
                                    <option value="sickLeave">Sick Leave</option>
                                    <option value="medicalLeave">Medical Leave</option>
                                    <option value="marriageLeave">Marriage</option>
                                    <option value="pregnancyLeave">Pregnancy Leave</option>
                            </select>
                        </div>
                        
                        <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class='col-6' htmlFor="to_date">Subject</label>
                            <input class="col-6"  type="text" name="reason" id="reason" required placeholder="Reason" 
                             onChange={(e) => setReason(e.target.value)}
                            />
                        </div>
                        <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class='col-6' htmlFor="to_date">Description</label>
                            <textarea class="col-6" name="leave_application_msg" id="leave_application_msg" placeholder="...in detail" cols="30" rows="10" 
                             onChange={(e) => setMessage(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class='col-6' htmlFor="to_date">Medical Documents (If Required)
                        </label>
                            <input type="file" name="medical_document" class="col-6"   id="medical_document" 
                             onChange={(e) => setDoc1(e.target.files[0])}
                            />
                        </div>

                        <div class="mt-50" style={{marginLeft:'600px'}}>
                        <button type="button" class=" nbr butPad px-5 py-2 my-5 fw-bolder btn btn-primary" 
                         onClick={SubmitLeaveRequest}
                        >Submit
                        </button>
                        </div>
                    </form>
    </div>
    </div>
  </div>
</div>
<div class="row">
     <div class="col-lg-12" id="images">
  </div>
</div>
    </div>	
    <Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>	   */}
</Helmet>
</div>

  )
}
