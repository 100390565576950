import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import axios from "axios";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet'

export default function EmployeeDocumentWallet() {

    const [walletList, setwalletList] = useState([]);
    const token = localStorage.getItem('token')
    const nav = useNavigate()

    const documentwalletList = async () => {
        axios({
            'method':'GET',
            'url': 'https://node.firehrm.com/HRMS/HRMS/admin/employeeGetOwn-WalletData',
            'headers':{
                'Authorization':token
            }   
        })
        .then((responce) => {
            console.log(responce)
            setwalletList(responce?.data?.data)
        })
        .catch((error) => {
            console.log(error)
        })
    } 
    
    useEffect(() =>  {
        documentwalletList();
    }, []);

function view(e,x){
    e.preventDefault()
    nav('/viewDocuments',{state:x})
}



  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>
   
<div class="col-11 ms-50">

<div class="box p-50">
<div class="box-header with-border">
 <h3 style={{marginRight:'700px'}} class="box-title">Document Wallet List</h3>
 {/* <a href='createDocumentWallet' class='btn btn-primary'>Create New</a> */}
</div>
{/* <!-- /.box-header --> */}
<div class="box-body">

<div class="table-responsive">
     <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
       <thead>
           <tr>
               <th>ID.NO</th>
               <th>Name</th>
               <th>Designation</th>
               <th>Number Of Documents</th>
               <th>Action</th>
           </tr>
           {walletList && walletList.map((x=>
           <tr>
               <td>{x?.employeeDetails?.identityNumber}</td>
               <td>{x?.employeeDetails?.name}</td>
               <td>{x?.employeeDetails?.designation?.designationName}</td>
               <td>{x?.certificate.length}</td>
               <td><button class='btn btn-info' onClick={(e)=>view(e,x)}><i class="fa fa-eye" aria-hidden="true"></i></button></td>
           </tr>
))}
           
       </thead>

   </table>
   
   </div>
   
</div>

</div>

</div>

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
