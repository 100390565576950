import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { saveAs } from "file-saver";

export default function SingleUpdatedPayslip() {
const state = useLocation()
console.log(state);
// organization data
const [organizationData, setorganizationData] = useState({});
const [singlePDF, setsinglePDF] = useState('');
const organizationDetails = async () => {
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/admin/getAll'   
    })
    .then((responce) => {
        console.log(responce.data.message[0])
        setorganizationData(responce?.data?.message?.[0])
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {
    organizationDetails();
}, []);

// payslip data
const [singledata, setsingleData] = useState({});
const singlePayslip = async () => {
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/admin/getById-updatePayslip/'+state?.state?._id   
    })
    .then((responce) => {
        console.log(responce.data.data[0])
        setsingleData(responce?.data?.data?.[0])
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {singlePayslip();}, []);
const basic = parseInt(singledata?.payslipDetails?.basicPay)
const totalDeduction = parseInt(singledata?.payslipDetails?.TDS) + parseInt(singledata?.payslipDetails?.employeeShareOfESI) + parseInt(singledata?.payslipDetails?.employeeShareOfPF) + parseInt(singledata?.payslipDetails?.employerShareOfESI) + parseInt(singledata?.payslipDetails?.employerShareOfPF) + parseInt(singledata?.payslipDetails?.lossOfPay)
const totalAllowance = parseInt(singledata?.payslipDetails?.HRA) + parseInt(singledata?.payslipDetails?.allowance) + parseInt(singledata?.payslipDetails?.cityCompensatory) + parseInt(singledata?.payslipDetails?.conveyanceAllowance) + parseInt(singledata?.payslipDetails?.medicalAllowance) + parseInt(singledata?.payslipDetails?.otherAllowance)
const tot =  totalAllowance-totalDeduction
const total = tot + basic
console.log(singledata);

const pdf = async () =>{
		axios({
			'method':'GET',
			'url': 'https://node.firehrm.com/HRMS/HRMS/admin/pdfCreater/'+state?.state?._id   
		})
		.then((responce) => {
			console.log(responce)
			
			setsinglePDF(responce?.data?.result?.filename)
			// if(responce.status == '200'){window.location.reload('false')}
		})
		.catch((error) => {
			console.log(error)
		})
	} 
	const saveFile = (x) => {
		saveAs(
			'https://node.firehrm.com/HRMS'+x
		);
	  };


  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
	<div class="wrapper mt-50">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>
    
{/* <!-- Content Wrapper. Contains page content --> */}
  <div class="content-wrapper ms-50">
	  <div class="container-full p-50">
		{/* <!-- Content Header (Page header) -->	   */}
		<div class="content-header">
			<div class="d-flex align-items-center">
				<div class="me-auto">
					{/* <h3 class="page-title ms-20">Payslip</h3> */}
					{/* <div class="d-inline-block align-items-center">
						<nav>
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
								<li class="breadcrumb-item" aria-current="page">Invoice</li>
								<li class="breadcrumb-item active" aria-current="page">Invoice Sample</li>
							</ol>
						</nav>
					</div> */}
				</div>
				
			</div>
		</div>  

		{/* <!-- Main content --> */}
		<section class="invoice printableArea">
		  <div class="row">
			<div class="col-12">
			  <div class="bb-1 clearFix">
				<div class="text-end pb-15">
					<button onClick={()=>pdf()} class="btn btn-success me-5" type="button"> <span><i class="fa fa-print"></i>CREATE PDF</span> </button>
					{singlePDF == "" ? "":<button onClick={()=>saveFile(singlePDF)} id="print2" class="btn btn-warning ms-5" type="button"> <span><i class="fa fa-print"></i>DOWNLOAD</span> </button>}
				</div>	
			  </div>
			</div>
			<div class="col-12">
			  <div class="page-header">
				<h2 class="d-inline"><span class="fs-30">Invoice Sample</span></h2>
				<div class="pull-right text-end">
				<h3>{singledata?.payslipDetails?.monthAndYear}</h3>
				</div>	
			  </div>
			</div>
			{/* <!-- /.col --> */}
		  </div>
		  <div class="row invoice-info">
			<div class="col-md-6 invoice-col">
			  <strong>From</strong>	
			  <address>
				<strong class="text-blue fs-24">{organizationData?.companyName}</strong><br/>
				<strong class="d-inline">{organizationData?.websiteLink}</strong><br/>
				<strong>Phone: {organizationData?.contact} &nbsp;&nbsp;&nbsp;&nbsp; Email: {organizationData?.email}</strong>  
			  </address>
			</div>
			{/* <!-- /.col --> */}
			<div class="col-md-6 invoice-col text-end">
			  <strong>To</strong>
			  <address>
				<strong class="text-blue fs-24">{singledata?.payslipDetails?.EmployeeDetails?.name}</strong><br/>
				{singledata?.payslipDetails?.EmployeeDetails?.designation?.designationName}<br/>
				<strong>Phone: 	{singledata?.payslipDetails?.EmployeeDetails?.contact} &nbsp;&nbsp;&nbsp;&nbsp; Email: 	{singledata?.EmployeeDetails?.email}</strong>
			  </address>
			</div>
			{/* <!-- /.col --> */}
			<div class="col-sm-12 invoice-col mb-15">
				<div class="invoice-details row no-margin">
				  <div class="col-md-6 col-lg-3"><b>Identity Number: </b>	{singledata?.payslipDetails?.EmployeeDetails?.identityNumber}</div>
				  {/* <div class="col-md-6 col-lg-3"><b>Order ID:</b> FC12548</div>
				  <div class="col-md-6 col-lg-3"><b>Payment Due:</b> 14/08/2018</div>
				  <div class="col-md-6 col-lg-3"><b>Account:</b> 00215487541296</div> */}
				</div>
			</div>
		  {/* <!-- /.col --> */}
		  </div>
		  <div class="row">
			<div class="col-12 table-responsive">
			  <table class="table table-bordered">
				<tbody>
               <tr>
                   <th>Basic Pay</th>
                   <td>{singledata?.payslipDetails?.basicPay}</td>
               </tr>
               <tr>
                   <th>Loss Of Pay</th>
                   <td>{singledata?.payslipDetails?.lossOfPay}</td>
               </tr>
               <tr>
                   <th>HRA</th>
                   <td>{singledata?.payslipDetails?.HRA}</td>
               </tr>
               <tr>
                   <th>Employee Share Of PF</th>
                   <td>{singledata?.payslipDetails?.employeeShareOfPF}</td>
               </tr>
               <tr>
                   <th>Employee Share Of ESI</th>
                   <td>{singledata?.payslipDetails?.employeeShareOfESI}</td>
               </tr>
               <tr>
                   <th>Employer Share Of PF</th>
                   <td>{singledata?.payslipDetails?.employerShareOfPF}</td>
               </tr>
               <tr>
                   <th>Employer Share Of ESI</th>
                   <td>{singledata?.payslipDetails?.employerShareOfESI}</td>
               </tr>
               
               <tr>
                   <th>Medical Allowance</th>
                   <td>{singledata?.payslipDetails?.medicalAllowance}</td>
               </tr>
               <tr>
                   <th>Conveyance Allowance</th>
                   <td>{singledata?.payslipDetails?.conveyanceAllowance}</td>
               </tr>
               <tr>
                   <th>City Compensatory</th>
                   <td>{singledata?.payslipDetails?.cityCompensatory}</td>
               </tr>
               <tr>
                   <th>Allowance</th>
                   <td>{singledata?.payslipDetails?.allowance}</td>
               </tr>
               <tr>
                   <th>Other Allowance</th>
                   <td>{singledata?.payslipDetails?.otherAllowance}</td>
               </tr>
               <tr>
                   <th>TDS</th>
                   <td>{singledata?.payslipDetails?.TDS}</td>
               </tr>
               <tr>
                   <th>Other Deduction</th>
                   <td>{singledata?.payslipDetails?.otherAllowance}</td>
               </tr>
				</tbody>
			  </table>
			</div>
			{/* <!-- /.col --> */}
		  </div>
		  <div class="row">
			<div class="col-12 text-end">
				{/* <p class="lead"><b>Payment Due</b><span class="text-danger"> 14/08/2018 </span></p> */}

				<div>
					<p>Basic Pay       : {singledata?.payslipDetails?.basicPay}</p>
					<p>Total-Allowance : {totalAllowance}</p>
					<p>Total-Deduction : {totalDeduction}</p>
				</div>
				<div class="total-payment">
					<h3><b>Total :</b> {total}</h3>
				</div>

			</div>
			{/* <!-- /.col --> */}
		  </div>
		  <div class="row no-print">
			<div class="col-12">
			  {/* <button type="button" class="btn btn-success pull-right"><i class="fa fa-credit-card"></i> Submit Payment */}
			  {/* </button> */}
			</div>
		  </div>
		</section>
		{/* <!-- /.content --> */}
	  </div>
  </div>
  {/* <!-- /.content-wrapper --> */}
 

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
