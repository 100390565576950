import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import axios from "axios";
import {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet'

export default function ViewSAP() {
  const [SAP, setSAP] = useState('')
  const token = localStorage.getItem('token')
  const view = async () => {
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/employee/getAll-suggestion',
        'headers':{
            'Authorization':token
        }   
    })
    .then((responce) => {
        console.log(responce)
        setSAP(responce?.data?.data)
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {
    view();
}, []);

function Delete(e,x){
  e.preventDefault()
  axios({
      'method':'DELETE',
      'url': 'https://node.firehrm.com/HRMS/HRMS/employee/delete-suggestion/'+x?._id,
      'headers':{
          'Authorization':token
      }   
  })
  .then((responce) => {
      console.log(responce)
      if(responce.status=='200'){window.location.reload('false')}
  })
  .catch((error) => {
      console.log(error)
  })
  }

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
  <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>
<div class="row">

<div class="col-lg-11 ms-50">

  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title"><strong>FEEDBACKS</strong></h3>
    </div>
    <div className='p-50'>
          {SAP && SAP.map((x=>
					<div class="box-body mb-100">
						<div class="row">
							<label class="col-6">Name :</label>
							<h5 type="text" class="col-6">{x?.employeeName}</h5>
						</div>
						<div class="row">
							<label class="col-6">Email :</label>
							<h5 type="password" class="col-6">{x?.email}</h5>
						</div>
						<div class="row">
							<label class="col-6">Message :</label>
							<h5 rows="5" cols="5"class="col-6">{x?.message}</h5>
						</div>
              <div style={{marginLeft:'1800px'}}>
                  <button class='btn btn-danger' onClick={(e)=>Delete(e,x)}>DELETE</button>
              </div>
              <hr />
					</div>
          ))}
          </div>
      </div>
    </div>
    <Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
</div>
  )
}
