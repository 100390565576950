import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Nav from './nav'
export default function Header() {
  const id = localStorage.getItem('id')
  const [EmployeeData, setEmployeeData] = useState('');

  const employeeDetails = async () => {
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/employee/getById/'+ id
    })
    .then((response) => {
        console.log(response)
        setEmployeeData(response?.data?.data?.[0])
        console.log(EmployeeData)
    })
    .catch((error) => {
        console.log(error)
    }
    )
}
useEffect(() =>{employeeDetails();},[]);

const [organizationData, setorganizationData] = useState({});

console.log(organizationData)
const organizationDetails = async () => {
    // const token = localStorage.getItem('token');
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/admin/getAll'   
    })
    .then((responce) => {
        console.log(responce)
        setorganizationData(responce?.data?.message?.[0])
      
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {
    organizationDetails();
}, []);

  return (
    <header class="main-header">
        <div class="inside-header">
        <div class="d-flex align-items-center logo-box justify-content-start">
          {/* <!-- Logo --> */}
          <Link to="/index" class="logo">
            {/* <!-- logo--> */}
            <div class="logo-mini w-30">
              <span class="light-logo"><img src="images/logo-letter.png" alt="logo"/></span>
              <span class="dark-logo"><img src="images/logo-letter-white.png" alt="logo"/></span>
            </div>
            <div class="logo-lg">
              <span class="light-logo"><img src="images/logo-dark-text.png" alt="logo"/></span>
              <span class="dark-logo"><img src="images/logo-light-text.png" alt="logo"/></span>
            </div>
          </Link>	
        </div>  
        {/* <!-- Header Navbar --> */}
        <nav class="navbar navbar-static-top" style={{marginLeft:"900px"}}>
          {/* <!-- Sidebar toggle button--> */}
        
    
          <div class="navbar-custom-menu r-side">
          <ul class="nav navbar-nav">
            <li class="btn-group nav-item">
              <a href="#" class="waves-effect waves-light nav-link btn-primary-light svg-bt-icon" title="" data-bs-toggle="modal" data-bs-target="#quick_actions_toggle">
                <i class="fa-solid fa-table"><span class="path1"></span><span class="path2"></span></i>
              </a>
            </li>
             
				{/* <li class="btn-group nav-item d-xl-inline-flex d-none">
					<a href="#" class="waves-effect waves-light nav-link btn-primary-light svg-bt-icon" title="" data-bs-toggle="modal" data-bs-target="#quick_panel_toggle">
						<i class="fa-solid fa-box"><span class="path1"></span><span class="path2"></span></i>
					</a>
				</li>
				<li className="btn-group nav-item d-xl-inline-flex d-none">
					<a href="#" className="waves-effect waves-light nav-link btn-primary-light svg-bt-icon" title="" id="live-chat">
						<i className="fa-solid fa-comment"><span className="path1"></span><span className="path2"></span></i>
					</a>
				</li> */}
            {/* <!-- User Account--> */}
            <li class="dropdown user user-menu">
              <a href="#" class="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent p-0 no-shadow" title="User" data-bs-toggle="modal" data-bs-target="#quick_user_toggle">
                {localStorage.getItem('role')=='employee' ?
                  <div class="d-flex pt-1">
                  <div class="text-end me-10">
                    <p class="pt-5 fs-14 mb-0 fw-700 text-primary">{EmployeeData?.name}</p>
                    <small class="fs-10 mb-0 text-uppercase text-mute">{EmployeeData?.designation?.designationName}</small>
                  </div>
                  <img src={EmployeeData?.profileImage} class="avatar rounded-10 bg-primary-light h-40 w-40" alt="" />
                </div>:<div class="d-flex pt-1">
                  <div class="text-end me-10">
                    <p class="pt-5 fs-14 mb-0 fw-700 text-primary">{organizationData?.companyName}</p>
                    <small class="fs-10 mb-0 text-uppercase text-mute">Admin</small>
                  </div>
                  <img src={organizationData?.companyLogo} class="avatar rounded-10 bg-primary-light h-40 w-40" alt="" />
                </div>}
              </a>
            </li>
    
          </ul>
         
          </div>
          
        </nav>
        
        </div>
        <Nav/>
        <Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
      </header>
  )
}
