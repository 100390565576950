import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import axios from "axios";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet'

export default function CreateCarosel() {
    const token = localStorage.getItem('token')
    const nav = useNavigate()
    const [carousalImage,setimg]=useState('');
    const [image,setimg1]=useState([]);
    const [carousalImageTitle, setName] = useState('');
console.log(carousalImage,carousalImageTitle);
    async function createCarosel(e){
    //   await  addImg(e)
     await axios({
        'method': 'POST',
        'url':'https://node.firehrm.com/HRMS/HRMS/carousal/createCarousal',
        'data': {carousalImage,carousalImageTitle}

}).then((resp)=>{
    console.log(resp);
    // setimg(resp);
     if(resp.status == '200'){nav('/index')}
} )
.catch((error)=>{
    console.log(error)
    })
}
console.log(image[0]);

function addImg(){
  
    const formdata1 = new FormData();
    for(var i = 0;i < image.length ; i++)
    formdata1.append('image',image[i]);
   axios({
    'method': 'POST',
    'url':'https://node.firehrm.com/HRMS/HRMS/admin/image',
    'data':formdata1
    }).then((response)=>{
        console.log(response)
        setimg(response?.data?.data?.image)
    //     if(response.status=='200'){
    //         window.location.reload(false)
    //         nav('/createCarosel')}
    }
    )
    .catch((error)=>{
        console.log(error)
    })
}
useEffect(()=>{
    if (image.length == 0){
        return;
    }
    else{
       
            addImg();
         
    }
})


const [carouselList, setcarouselList] = useState([]);
console.log(carouselList);
const carousel = async () => {
  axios({
      'method':'GET',
      'url': 'https://node.firehrm.com/HRMS/HRMS/carousal/getAllCarousel', 
  })
  .then((responce) => {
      console.log(responce)
      setcarouselList(responce?.data?.data)
      
  })
  .catch((error) => {
      console.log(error)
  })
} 

useEffect(() =>  {
  carousel();
}, []);

function Remove(e,x){
    e.preventDefault()
    axios({
        'method':'DELETE',
        'url': 'https://node.firehrm.com/HRMS/HRMS/carousal/deleteCarousel/'+x,
        'headers':{
            'Authorization':token
        }   
    })
    .then((responce) => {
        console.log(responce)
        if(responce.status=='200'){
            window.location.reload(false)
            nav('/createCarosel')}
    })
    .catch((error) => {
        console.log(error)
    })
    } 


  return (
<div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150 ms-50 me-50">
        <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="row">

  <div class="col-lg-12">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><strong>CREATE CAROUSEL</strong></h3>
      </div>
      <div class="panel-body p-50">
<form> 
                       <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
							<label class='col-6'>Carosel Name</label>
							<input class='col-6'onChange={(e)=>setName(e.target.value)} type="text" placeholder="Name"></input>
						</div>
						<div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
							<label class='col-6'>Carosel Image</label>
							<input class='col-6' type="file" multiple onChange={(e)=>setimg1(e.target.files)} placeholder="Img"></input>
                            {/* <button className='col-1 btn-primary' onClick={(e)=>addImg(e)}>UPLOAD</button> */}
						</div>
                        <div className='mt-50' style={{marginLeft:'600px'}}>
                            <button 
                             onClick={(e)=>createCarosel(e)} 
                            class='btn btn-primary'>SUBMIT</button>
                        </div>
					  
                        </form>
    </div>
    <div className='f' style={{marginLeft:"360px"}}>
    {carouselList.map((x) => (
    <div className='m-5 row mb-50'>
        {console.log(x)}
        <div className='col-6'>
        <img src={x?.carousalImage} alt="img" width={400} height={300}></img>
        <button onClick={(e)=>Remove(e,x?._id)} className='btn btn-danger' style={{marginBottom:'260px'}}>X</button>
        </div>
    </div>
))}
</div>
    </div>
  </div>
</div>
<div class="row">
     <div class="col-lg-12" id="images">
  </div>
</div>
    </div>	
    <Footer/> 

<Helmet>     
    {/* <!-- Vendor JS -->  */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
    $(document).ready(function(){
    $('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>

</div> 
  )
}

