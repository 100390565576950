import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function OrganizationDetails() {

const [organizationData, setorganizationData] = useState({});
const navToUpdate =useNavigate()
console.log(organizationData)
const organizationDetails = async () => {
    // const token = localStorage.getItem('token');
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/admin/getAll'   
    })
    .then((responce) => {
        console.log(responce)
        setorganizationData(responce?.data?.message?.[0])
      
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {
    organizationDetails();
}, []);

function Update(e,x){
    navToUpdate('/updateOrganization',{state:x})
}

  return (

//     <div class="layout-top-nav light-skin theme-primary fixed">
	
//     <div class="wrapper mt-200">
//     <Header/>

// <UserSideBar/>

// <QuickActionToggle/>
// <div class="col-12">

// <div class="box">
//    <div class="box-header with-border">
//      <h3 class="box-title" style={{marginRight:"900px"}}>Organization</h3>
//      {localStorage.getItem('role')=='admin'?<button class='btn btn-success' 
//       onClick={(e)=>Update(e,organizationData)}
//      >UPDATE</button>:''}
//    </div>
//    {/* <!-- /.box-header --> */}
//    <div class="box-body">
//        <div class="table-responsive">
    
//                <div className='flex'>
//                    <div className=''><img src={organizationData?.companyLogo} width='300px' alt="" /></div>
//                    {/* <div>Company Name</div> */}
//                    <div className='h1'>{organizationData?.companyName}</div>
//                </div>
//                <div>
//                    <div>Office Image</div>
//                    <div><img src={organizationData?.officeImage} alt="" /></div>
//                </div>
//                <div>
//                    <div>Email</div>
//                    <div>{organizationData?.email}</div>
//                </div>
//                <div>
//                    <div>Contact</div>
//                    <div>{organizationData?.contact}</div>
//                </div>
//                <div>
//                    <div>About</div>
//                    <div>{organizationData?.about}</div>
//                </div> 
//                <div>
//                    <div>Web Address</div>
//                    <div>{organizationData?.websiteLink}</div>
//                </div>
//                <div>
//                    <div>CEO</div>
//                    <div>{organizationData?.CEO}</div>
//                </div>
//                <div>
//                    <div>Founded On</div>
//                    <div>{organizationData?.foundedOn}</div>
//                </div>
//                <div>
//                    <div>Head Quarters</div>
//                    <div>{organizationData?.headQuarters}</div>
//                </div>
//                <div>
//                    <div>Revenue</div>
//                    <div>{organizationData?.revenue}</div>
//                </div>
//                <div>
//                    <div>Number Of Employees</div>
//                    <div>{organizationData?.numberOfEmployees}</div>
//                </div>
//                <div>
//                    <div>Founders</div>
//                    <div>{organizationData?.founders}</div>
//                </div>
//                <div>
//                    <div>Achievements</div>
//                    <div><iframe src={organizationData?.achievementDocuments} frameborder="0"></iframe></div>
//                </div>
//                <div>
//                    <div>Subsidiaries</div>
//                    <div>{organizationData?.subsidiaries}</div>
//                </div>
          
//        </div>
//    </div>
//    {/* <!-- /.box-body --> */}
//  </div>
//  {/* <!-- /.box -->       */}
// </div>

<div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
          <Header/>
  
          <UserSideBar/>
          
          <QuickActionToggle/>
          {/* <!-- ADd Employee --> */}
  <div class="content-wrapper" style={{marginTop: "20px",marginLeft:"30px"}}>
    <div class="main-body p-50">
    
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb" class="main-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="index">Home</a></li>
              <li class="breadcrumb-item"><a href="">Organization</a></li>
              {/* <li class="breadcrumb-item active" aria-current="page">Employee Profile</li> */}
            </ol>
          </nav>
          {/* <!-- /Breadcrumb --> */}
    
          <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                    <img src={organizationData?.companyLogo} alt="Admin" class="rounded-circle" width="300px" height="305px" />
                    <div class="mt-3">
                      <h4>{organizationData?.companyName}</h4>
                      <p class="text-secondary mb-1">{organizationData?.websiteLink}</p>
                      <p class="text-muted font-size-sm">Identity Number: {organizationData?.CEO}</p>
                     
                      {localStorage.getItem('role')=='admin'?<button onClick={(e)=>Update(e,organizationData)} class="btn btn-primary " target="__blank" href="">UPDATE</button>:''}
                      {/* {localStorage.getItem('role')=='employee'?'':<button onClick={(e)=>DeleteEmployeeProfile(e)} class="btn btn-outline-danger">Delete Profile</button>} */} 
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="card mt-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                    
                      Ph.No</h6>
                    <span class="text-secondary">{organizationData?.contact}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                     
                      Mail-ID</h6>
                    <span class="text-secondary">{organizationData?.email}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                     
                      About</h6>
                    <span class="text-secondary">{organizationData?.about}</span>
                  </li>
                  
                </ul>
              </div> */}
            </div>
            <div class="col-md-8">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Founded-On</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.foundedOn}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Head-Quarters</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.headQuarters}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Revenue</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.revenue}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Number Of Employees</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.numberOfEmployees}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Founded By</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.founders}
                    </div>
                  </div>
                <hr/>
                <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Partners</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.subsidiaries}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Phone Number</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.subsidiaries}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Mail-ID</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.subsidiaries}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Address</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {organizationData?.subsidiaries}
                    </div>
                  </div>
                  <hr/>
                </div>
                
              </div>
              </div>
              </div>
  
              {/* <div class="row gutters-sm">
                <div class="col-sm-6 mb-3">
                  <div class="card h-100">
                    <div class="card-body">
                      <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">Team Status</i></h6>
                      <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Designation</h6>
                    <span class="text-secondary">{employeeData?.designation?.designationName}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Project Manager</h6>
                    <span class="text-secondary">{employeeData?.designation?.projectManager}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Team Leader</h6>
                    <span class="text-secondary">{employeeData?.designation?.teamLeader}</span>
                  </li>
                </ul>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mb-3">
                  <div class="card h-100">
                    <div class="card-body">
                      <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">Bank Status</i></h6>
                      <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Account Holder</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.accountHolderName}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Account Number</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.accountNumber}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Branch Name</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.branchName}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      IFSC Code</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.IFSCCode}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      Pan Number</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.panNumber}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      PF Number</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.pfNumber}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      ESI Number</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.esiNumber}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">
                      UAN Number</h6>
                    <span class="text-secondary">{employeeData?.bankDetails?.uanNumber}</span>
                  </li>
                </ul>
                    </div>
                  </div>
                </div>
              </div> */}
        </div>
    </div>


<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
