import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import axios from "axios";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet'

export default function LeadersList() {
    const [leaders, setleaders] = useState([]);
    const leadersList = async () => {
        axios({
            'method':'GET',
            'url': 'https://node.firehrm.com/HRMS/HRMS/employee/leaderList',  
        })
        .then((responce) => {
            console.log(responce)
            setleaders(responce?.data?.data)
        })
        .catch((error) => {
            console.log(error)
        })
    } 
    
    useEffect(() =>  {
        leadersList();
    }, []);


  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-10">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>
   
<div class="col-12">

<div class="box">
<div class="box-header with-border">
{/* <!-- Content Wrapper. Contains page content --> */}
  <div class="content-wrapper">
	  <div class="container-full">
		{/* <!-- Content Header (Page header) -->	   */}
		<div class="content-header">
			<div class="d-flex align-items-center">
				<div class="me-auto">
					<h3 class="page-title">Userlist grid</h3>
					<div class="d-inline-block align-items-center">
						<nav>
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
								<li class="breadcrumb-item" aria-current="page">Contact</li>
								<li class="breadcrumb-item" aria-current="page">Userlist grid</li>
							</ol>
						</nav>
					</div>
				</div>
				
			</div>
		</div>  

		{/* <!-- Main content --> */}
		<section class="content">

			<div class="row">

			  <div class="col-12 col-lg-4">
				<div class="box ribbon-box">
				  <div class="ribbon-two ribbon-two-primary"><span>CEO</span></div>
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/1.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>				
						</div>
					  <div class="text-center">
						<h3 class="my-10"><a href="#">Tristan</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Designer</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
			  <div class="col-12 col-lg-4">
				<div class="box ribbon-box">
				  <div class="ribbon-two ribbon-two-danger"><span>MD</span></div>
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/2.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>			
						</div>
						<h3 class="my-10"><a href="#">Sophia</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Full Stack Developer</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
			  <div class="col-12 col-lg-4">
				<div class="box ribbon-box">
				  <div class="ribbon-two ribbon-two-success"><span>Owner</span></div>
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/3.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>				
						</div>
						<h3 class="my-10"><a href="#">Jacob</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Support Agent</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
			  <div class="col-12 col-lg-4">
				<div class="box">
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/4.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>			
						</div>
						<h3 class="my-10"><a href="#">Isabella</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Designer</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
			  <div class="col-12 col-lg-4">
				<div class="box ribbon-box">
				  <div class="ribbon-two ribbon-two-info"><span>Top Agent</span></div>
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/5.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>					
						</div>
						<h3 class="my-10"><a href="#">Emma</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Support Agent</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
			  <div class="col-12 col-lg-4">
				<div class="box">
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/6.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>					
						</div>
						<h3 class="my-10"><a href="#">William</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Marketing Department</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
              {leaders && leaders.map(x=>
			  <div class="col-12 col-lg-4">
				<div class="box" style={{width:"400px",height:"100px",marginBottom:"250px"}}>
				  <div class="box-header no-border p-0" style={{width:"400px",height:"100px"}}>				
					<a href="#">
					  <img class="img-fluid" src={x?.profileImage} style={{width:"400px",height:"200px"}} />
					</a>
				  </div>
				  <div class="box-body" style={{marginTop:"100px"}}>
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>					
						</div>
						<h3 class="my-10"><a href="#">{x?.name}</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">{x?.designation?.designationName}</h6>
						<p class="text-fade w-p85 mx-auto">{x?.address}</p>
					  </div>
				  </div>
				</div>
			  </div>
            )}
			  {/* <div class="col-12 col-lg-4">
				<div class="box">
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/8.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>					
						</div>
						<h3 class="my-10"><a href="#">Michael</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Designer</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div>
				</div>
			  </div>
			  <div class="col-12 col-lg-4">
				<div class="box">
				  <div class="box-header no-border p-0">				
					<a href="#">
					  <img class="img-fluid" src="images/avatar/375x200/2.jpg" alt=""/>
					</a>
				  </div>
				  <div class="box-body">
					  <div class="text-center">
						<div class="user-contact list-inline text-center">
							<a href="#" class="btn btn-circle mb-5 btn-facebook"><i class="fa-brands fa-facebook-f"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-instagram"><i class="fa-brands fa-instagram"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-twitter"><i class="fa-brands fa-twitter"></i></a>
							<a href="#" class="btn btn-circle mb-5 btn-warning"><i class="fa fa-envelope"></i></a>					
						</div>
						<h3 class="my-10"><a href="#">Sophia</a></h3>
						<h6 class="user-info mt-0 mb-10 text-fade">Full Stack Developer</h6>
						<p class="text-fade w-p85 mx-auto">125 Ipsum Lorem Ave, Suite 458 New York, USA 154875 </p>
					  </div>
				  </div> */}
				{/* </div> */}
			  {/* </div> */}
			</div>

		</section>
		{/* <!-- /.content --> */}
	  </div>
  </div>
  {/* <!-- /.content-wrapper --> */}
   
</div>

</div>

</div>

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
