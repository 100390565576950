import React,{useState} from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function RecoverPassword() {
	const [email, setemail] = useState('');
    const [otp, setotp] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [confirmPassword,setconfirmPassword] = useState('');
    const token = localStorage.getItem('token')
    const nav = useNavigate()

	const forgetPassword = () => {
        axios({
        'method': 'POST',
        'url': 'https://node.firehrm.com/HRMS/HRMS/employee/forgetPassword', 
        headers:{
            'Authorization':token,
            'Content-Type':'application/json'
        },
        'data': {email}
        
         }).then((resp)=>{
			console.log(resp);
		 })
    
    }

	const createPassword = () => {
        axios({
        'method': 'POST',
        'url':'https://node.firehrm.com/HRMS/HRMS/employee/forgetPassword',
        headers:{
			'Authorization':token,
            'Content-Type':'application/json'
        },
        'data': {otp,newPassword,confirmPassword,email}
        }).then((response)=>{
            console.log(response)
            const res = response;
            localStorage.clear();
             if(response.status == '200'){nav('/')}
            // window.location.reload(false)
        }).catch((error)=>{
            console.log(error)
        })
    }
  return (
    <div class="hold-transition theme-primary bg-img" style={{backgroundImage: "url(images/auth-bg/bg-2.jpg)"}}>
	
	<div class="container h-p100">
		<div class="row align-items-center justify-content-md-center h-p100">
			
			<div class="col-12">
				<div class="row justify-content-center g-0">
					<div class="col-lg-5 col-md-5 col-12">						
						<div class="bg-white rounded10 shadow-lg">
							<div class="content-top-agile p-20 pb-0">
								<h3 class="mb-0 text-primary">Recover Password</h3>								
							</div>
							<div class="p-40">
								<form action="index.html" method="post">
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-envelope"></i></span>
											<input onChange={(e)=>setemail(e.target.value)} type="email" class="form-control ps-15 bg-transparent" placeholder="Your Email"/>
										</div>
										<div class="row mb-5">
										<div class="col-12 text-center mb-5">
										  <button onClick={forgetPassword} type="button" class="btn btn-info margin-top-10 mb-5">Send OTP</button>
										</div>
										{/* <!-- /.col --> */}
									  </div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-user"></i></span>
											<input onChange={(e)=>setotp(e.target.value)} type="tel" class="form-control ps-15 bg-transparent" placeholder="OTP"/>
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text  bg-transparent"><i class="fa-solid fa-lock"></i></span>
											<input onChange={(e)=>setnewPassword(e.target.value)} type="password" class="form-control ps-15 bg-transparent" placeholder="Create Password"/>
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text  bg-transparent"><i class="fa-solid fa-lock"></i></span>
											<input onChange={(e)=>setconfirmPassword(e.target.value)} type="password" class="form-control ps-15 bg-transparent" placeholder="Confirm Password"/>
										</div>
									</div>
									</div>
									  <div class="row">
										<div class="col-12 text-center">
										  <button onClick={createPassword} type="button" class="btn btn-info margin-top-10">Reset</button>
										</div>
										{/* <!-- /.col --> */}
									  </div>
								</form>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	


<Helmet>
{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
	$(document).ready(function(){
	$('.dropdown-toggle').dropdown()
});
</script> */}

</Helmet>

</div>
  )
}
