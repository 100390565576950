import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Login from "./PAGES/Employee/login";
import Register from "./PAGES/Employee/register"
import Index from "./PAGES/Employee/index";
import AuthLockscreen from "./PAGES/Employee/authLockscreen";
import RecoverPassword from "./PAGES/Employee/recoverPassword";
import ViewAllEmployee from "./PAGES/Employee/viewAllEmployee";
import ViewEmployeeProfile from "./PAGES/Employee/viewEmployeeProfile";
import EditEmployeeProfile from "./PAGES/Employee/editEmployeeProfile";
import AddEmployeeProfile from "./PAGES/Employee/addEmployeeProfile";
import LoginAndLogout from "./PAGES/Employee/loginAndLogout";
import SingleLoginAndLogout from "./PAGES/Employee/singleLoginAndLogout";

import CreateLeaveApplication from "./PAGES/LeaveApply/createLeaveApplication";
import Getallleaveapplication from "./PAGES/LeaveApply/getallleaveapplication";
import AdminViewsingleLeave from "./PAGES/LeaveApply/adminViewSingleLeave";
import LeaveApplicationHistory from "./PAGES/LeaveApply/leaveApplicationHistory";

import OrganizationDetails from "./PAGES/Organization/organizationDetails";
import UpdateOrganization from "./PAGES/Organization/updateOrganization";
import LeadersList from "./PAGES/Organization/leadersList";

import CreatePayslip from "./PAGES/Payslip/createPayslip";
import ViewAllPayslip from "./PAGES/Payslip/viewAllPayslip";
import SinglePayslip from "./PAGES/Payslip/singlePayslip";
import ViewUpdatedPayslip from "./PAGES/Payslip/viewUpdatedPayslip";
import SingleUpdatedPayslip from "./PAGES/Payslip/singleUpdatedPayslip";
import UpdatePayslip from "./PAGES/Payslip/updatePayslip";
import MonthlyPayslipForEmployee from "./PAGES/Payslip/monthlyPayslipForEmployee";

import CreateDocumentWallet from "./PAGES/Document Wallet/createDocumentWallet";
import DocumentWalletList from "./PAGES/Document Wallet/documentWalletList";
import ViewDocuments from "./PAGES/Document Wallet/viewDocuments";
import EditDocuments from "./PAGES/Document Wallet/editDocuments";
import EmployeeDocumentWallet from "./PAGES/Document Wallet/employeeDocumentWallet";

import CreateSAP from "./PAGES/suggestionAndProblem.jsx/createSAP";
import ViewSAP from "./PAGES/suggestionAndProblem.jsx/viewSAP";

import CreateCarosel from "./PAGES/Carosel/createCarosel";

import GetAllOpenings from "./PAGES/Interview Process/getAllOpenings";
import NewVacancy from "./PAGES/Interview Process/newVacancy";
import ViewOpening from "./PAGES/Interview Process/viewOpening";
import UpdateVacancy from "./PAGES/Interview Process/updateVacancy";

import CreateCandidate from "./PAGES/Candidate Selection/createCandidate";
import ViewCandidate from "./PAGES/Candidate Selection/viewCandidate";
import UpdateCandidate from "./PAGES/Candidate Selection/updateCandidate";



function App() {
  return (
    <Routes>
      {/* Employee */}
        <Route exact path = '/' element={<Login/>}/>
        <Route path = '/register' element={<Register/>}/>
        <Route path = '/index' element={<Index/>}/>
        <Route path = '/authLockscreen' element={<AuthLockscreen/>}/>
        <Route path = '/recoverPassword' element={<RecoverPassword/>}/>
        <Route path = '/viewAllEmployee' element={<ViewAllEmployee/>}/>
        <Route path = '/viewEmployeeProfile' element={<ViewEmployeeProfile/>}/>
        <Route path = '/editEmployeeProfile' element={<EditEmployeeProfile/>}/>
        <Route path = '/addEmployeeProfile' element={<AddEmployeeProfile/>}/>
        <Route path = '/loginAndLogout' element={<LoginAndLogout/>}/>
        <Route path = '/singleLoginAndLogout' element={<SingleLoginAndLogout/>}/>

        {/* Leave Apply */}
        <Route path = '/createLeaveApplication' element={<CreateLeaveApplication/>}/>
        <Route path = '/getallleaveapplication' element={<Getallleaveapplication/>}/>
        <Route path = '/adminViewsingleLeave' element={<AdminViewsingleLeave/>}/>
        <Route path = '/leaveApplicationHistory' element={<LeaveApplicationHistory/>}/>

        {/* Organization */}
        <Route path = '/OrganizationDetails' element={<OrganizationDetails/>}/>
        <Route path = '/updateOrganization' element={<UpdateOrganization/>}/>
        <Route path = '/leadersList' element={<LeadersList/>}/>

        {/* Payslip */}
        <Route path = '/createPayslip' element={<CreatePayslip/>}/>
        <Route path = '/viewAllPayslip' element={<ViewAllPayslip/>}/>
        <Route path = '/singlePayslip' element={<SinglePayslip/>}/>
        <Route path = '/viewUpdatedPayslip' element={<ViewUpdatedPayslip/>}/>
        <Route path = '/singleUpdatedPayslip' element={<SingleUpdatedPayslip/>}/>
        <Route path = '/updatePayslip' element={<UpdatePayslip/>}/>
        <Route path = '/monthlyPayslipForEmployee' element={<MonthlyPayslipForEmployee/>}/>

        {/* Document Wallet */}
        <Route path = '/createDocumentWallet' element={<CreateDocumentWallet/>}/>
        <Route path = '/documentWalletList' element={<DocumentWalletList/>}/>
        <Route path = '/viewDocuments' element={<ViewDocuments/>}/>
        <Route path = '/editDocuments' element={<EditDocuments/>}/>
        <Route path = '/employeeDocumentWallet' element={<EmployeeDocumentWallet/>}/>

        {/* Feedback */}
        <Route path = '/viewSAP' element={<ViewSAP/>}/>
        <Route path = '/createSAP' element={<CreateSAP/>}/>

        {/* Carosel */}
        <Route path = '/createCarosel' element={<CreateCarosel/>}/>

        {/* Interview Process */}
        <Route path = '/getAllOpenings' element={<GetAllOpenings/>}/>
        <Route path = '/newVacancy' element={<NewVacancy/>}/>
        <Route path = '/viewOpening' element={<ViewOpening/>}/>
        <Route path = '/updateVacancy' element={<UpdateVacancy/>}/>

        {/* Candidate Selection */}
        <Route path = '/createCandidate' element={<CreateCandidate/>}/>
        <Route path = '/viewCandidate' element={<ViewCandidate/>}/>
        <Route path = '/updateCandidate' element={<UpdateCandidate/>}/>
       
    </Routes>
  );
}

export default App;
