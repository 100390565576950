import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import axios from 'axios'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ViewEmployeeProfile() {
    const navToEdit = useNavigate()
    const [employeeData,setEmployeeData] = useState({});
    const {state}=useLocation();
    const token = localStorage.getItem('token');
     console.log(state)
     let id = ''
    {localStorage.getItem('role')=='employee'?id = localStorage.getItem('id') : id = state?._id}
    
    const employeeDetails = async () => {
        axios({
            'method':'GET',
            'url': 'https://node.firehrm.com/HRMS/HRMS/employee/getById/'+ id
        })
        .then((response) => {
            console.log(response)
            setEmployeeData(response?.data?.data?.[0])
            console.log(employeeData)
        })
        .catch((error) => {
            console.log(error)
        }
        )
    }
    useEffect(() =>{employeeDetails();},[]);

    function EditEmployeeProfile(e,x){
        navToEdit('/editEmployeeProfile',{state:x})
    }
    function AfterDeleteNav(){
        navToEdit('/viewAllEmployee')
    }

    function DeleteEmployeeProfile(e){
        axios({
            'method':'Delete',
            'url': 'https://node.firehrm.com/HRMS/HRMS/employee/delete/'+ id,
            headers: {
                'Authorization':token,
              'Content-type': 'application/JSON'
            }, 
        })
        .then((response) => {
            console.log(response)
            if (response.status=='200'){AfterDeleteNav()}
        })
        .catch((error) => {
            console.log(error)
        }
        )
    }

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
  <div class="wrapper mt-150">
        <Header/>

        <UserSideBar/>
        
        <QuickActionToggle/>
        {/* <!-- ADd Employee --> */}
<div class="content-wrapper ms-40" style={{marginTop: "20px"}}>
  <div class="main-body p-50">
  
        {/* <!-- Breadcrumb --> */}
        <nav aria-label="breadcrumb" class="main-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index">Home</a></li>
            <li class="breadcrumb-item"><a href="viewAllEmployee">Employee Profile List</a></li>
            <li class="breadcrumb-item" aria-current="page">Employee Profile</li>
          </ol>
        </nav>
        {/* <!-- /Breadcrumb --> */}
  
        <div class="row gutters-sm">
          <div class="col-md-4 mb-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <img src={employeeData?.profileImage} alt="Admin" class="rounded-circle" width="260" height="260px"/>
                  <div class="mt-3">
                    <h4>{employeeData?.name}</h4>
                    <p class="text-secondary mb-1">{employeeData?.designation?.designationName}</p>
                    <p class="text-muted font-size-sm">Identity Number: {employeeData?.identityNumber}</p>
                    <button onClick={(e)=>EditEmployeeProfile(e,employeeData)} class="btn btn-info " target="__blank" href="">Edit</button>
                    {localStorage.getItem('role')=='employee'?'':<button onClick={(e)=>DeleteEmployeeProfile(e)} class="btn btn-outline-danger">Delete Profile</button>}
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="card mt-3">
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                   
                    Ph.No</h6>
                  <span class="text-secondary">{employeeData?.contact}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    
                    Mail-ID</h6>
                  <span class="text-secondary">{employeeData?.email}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    
                    Address</h6>
                  <span class="text-secondary">{employeeData?.address}</span>
                </li>
                
              </ul>
            </div> */}
          </div>
          <div class="col-md-8">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Years Of Experience</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {employeeData?.yearsOfExperience}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Date of Joining</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {employeeData?.dateOfJoining}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Gender</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {employeeData?.gender}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Blood Group</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {employeeData?.bloodGroup}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Date Of Birth</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {employeeData?.dateOfBirth}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Phone Number</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                  {employeeData?.contact}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Mail-ID</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                  {employeeData?.email}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Address</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                  {employeeData?.address}
                  </div>
                </div>
                <hr/>
                {/* <div class="row">
                  <div class="col-sm-12">
                    <button onClick={(e)=>EditEmployeeProfile(e,employeeData)} class="btn btn-info " target="__blank" href="">Edit</button>
                  </div>
                </div> */}
                
              </div>
              
            </div>
            </div>
            </div>

            <div class="row gutters-sm">
              <div class="col-sm-6 mb-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">Team Status</i></h6>
                    <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Designation</h6>
                  <span class="text-secondary">{employeeData?.designation?.designationName}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Project Manager</h6>
                  <span class="text-secondary">{employeeData?.designation?.projectManager}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Team Leader</h6>
                  <span class="text-secondary">{employeeData?.designation?.teamLeader}</span>
                </li>
              </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">Bank Status</i></h6>
                    <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Account Holder</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.accountHolderName}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Account Number</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.accountNumber}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Branch Name</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.branchName}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    IFSC Code</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.IFSCCode}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    Pan Number</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.panNumber}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    PF Number</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.pfNumber}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    ESI Number</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.esiNumber}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    UAN Number</h6>
                  <span class="text-secondary">{employeeData?.bankDetails?.uanNumber}</span>
                </li>
              </ul>
                  </div>
                </div>
              </div>
            </div>
      </div>
  </div>
  <Footer/> 
  <Helmet>
  {/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script> */}
  </Helmet>
</div>
</div> 
  )
}
