import React from 'react'
import { Helmet } from 'react-helmet'

export default function Register() {
  return (

        <div class="hold-transition theme-primary bg-img" style={{backgroundImage: "url(images/auth-bg/bg-2.jpg)"}}>
	
	<div class="container h-p100">
		<div class="row align-items-center justify-content-md-center h-p100">
			
			<div class="col-12">
				<div class="row justify-content-center g-0">
					<div class="col-lg-5 col-md-5 col-12">
						<div class="bg-white rounded10 shadow-lg">
							<div class="content-top-agile p-20 pb-0">
								<h2 class="text-primary">Get started with Us</h2>
								<p class="mb-0">Register a new membership</p>							
							</div>
							<div class="p-40">
								<form action="index.html" method="post">
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-user"></i></span>
											<input type="text" class="form-control ps-15 bg-transparent" placeholder="Full Name"/>
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-envelope"></i></span>
											<input type="email" class="form-control ps-15 bg-transparent" placeholder="Email"/>
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-lock"></i></span>
											<input type="password" class="form-control ps-15 bg-transparent" placeholder="Password"/>
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="fa-solid fa-lock"></i></span>
											<input type="password" class="form-control ps-15 bg-transparent" placeholder="Retype Password"/>
										</div>
									</div>
									  <div class="row">
										<div class="col-12">
										  <div class="checkbox">
											<input type="checkbox" id="basic_checkbox_1" />
											<label htmlFor="basic_checkbox_1">I agree to the <a href="#" class="text-warning"><b>Terms</b></a></label>
										  </div>
										</div>
										{/* <!-- /.col --> */}
										<div class="col-12 text-center">
										  <button type="submit" class="btn btn-info margin-top-10">SIGN IN</button>
										</div>
										{/* <!-- /.col --> */}
									  </div>
								</form>				
								<div class="text-center">
									<p class="mt-15 mb-0">Already have an account?<a href="/" class="text-danger ms-5"> Sign In</a></p>
								</div>
							</div>
						</div>								

						<div class="text-center">
						  <p class="mt-20 text-white">- Register With -</p>
						  <p class="gap-items-2 mb-20">
							<a class="btn btn-social-icon btn-round btn-facebook" href="#"><i class="fa-brands fa-facebook-f"></i></a>
							<a class="btn btn-social-icon btn-round btn-twitter" href="#"><i class="fa-brands fa-twitter"></i></a>
							<a class="btn btn-social-icon btn-round btn-instagram" href="#"><i class="fa-brands fa-instagram"></i></a>
							</p>	
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>

<Helmet>
{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
	$(document).ready(function(){
	$('.dropdown-toggle').dropdown()
});
</script> */}
</Helmet>


</div>

  )
}
