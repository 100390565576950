import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Header from '../../componenet.jsx/header'
import UserSideBar from '../../componenet.jsx/userSideBar'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import Footer from '../../componenet.jsx/footer' 
import Slider from '../Carosel/carosuelComponent'
import axios from 'axios'
import Quickpanel from '../../componenet.jsx/quickPanel'

export default function Index() {
	// Employee Count
	const [employeesListDataCount,setEmployeesDataCount] = useState([]);
    const token = localStorage.getItem('token');
    console.log(employeesListDataCount)
    const employeesListDetails = async () => {
        axios({
            'method':'GET',
            'url':'https://node.firehrm.com/HRMS/HRMS/employee/getAll',
            'headers':{
                'Authorization':token 
            }
        })
        .then((response) =>{
            console.log(response)
            setEmployeesDataCount(response.data.count)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    useEffect(() => {employeesListDetails();},[]);
    // Feedback Count
	const [SAP, setSAP] = useState('')
	const view = async () => {
	  axios({
		  'method':'GET',
		  'url': 'https://node.firehrm.com/HRMS/HRMS/employee/getAll-suggestion',
		  'headers':{
			  'Authorization':token
		  }   
	  })
	  .then((responce) => {
		  console.log(responce)
		  setSAP(responce?.data?.count)
	  })
	  .catch((error) => {
		  console.log(error)
	  })
  } 
  
  useEffect(() =>  {
	  view();
  }, []);
// Pending Leave Application
  const [pending, setpending] = useState('')
	const viewPending = async () => {
	  axios({
		  'method':'GET',
		  'url': 'https://node.firehrm.com/HRMS/HRMS/employee/pendingLeave-Count',
		  'headers':{
			  'Authorization':token
		  }   
	  })
	  .then((responce) => {
		  console.log(responce)
		  setpending(responce?.data?.count)
	  })
	  .catch((error) => {
		  console.log(error)
	  })
  } 
  
  useEffect(() =>  {
	  viewPending();
  }, []);
// leaders list count
  const [leaders, setleaders] = useState([]);
  const leadersList = async () => {
	  axios({
		  'method':'GET',
		  'url': 'https://node.firehrm.com/HRMS/HRMS/employee/leaderList',  
	  })
	  .then((responce) => {
		  console.log(responce)
		  setleaders(responce?.data?.count)
	  })
	  .catch((error) => {
		  console.log(error)
	  })
  } 
  
  useEffect(() =>  {
	  leadersList();
  }, []);


  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
	<div class="wrapper mt-20">
	{/* <div id="loader"></div> */}
	
	<Header/>
    <UserSideBar/>
    <Quickpanel/>
    <QuickActionToggle/>
	
      
  {/* <!-- Content Wrapper. Contains page content --> */}
  <div class="content-wrapper">
	  <div class="container-full">
		{/* <!-- Main content --> */}
		<section class="content">
			<div class="row">
				<div class="col-xl-3 col-md-6 col-12">
					<div class="box">
						<div class="box-body">
							<h4 class="fw-500 mt-0">Total employees</h4>
							<div class="d-flex align-items-center justify-content-between">
								<h2 class="fw-600 my-0">{employeesListDataCount}</h2>
								{/* <h4 class="fw-500 my-0 text-success"><i class="me-10 mdi mdi-arrow-top-right"></i> +15%</h4> */}
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-md-6 col-12">
					<div class="box">
						<div class="box-body">
							<h4 class="fw-500 mt-0">Number of Feedback</h4>
							<div class="d-flex align-items-center justify-content-between">
								<h2 class="fw-600 my-0">{SAP}</h2>
								{/* <h4 class="fw-500 my-0 text-danger"><i class="me-10 mdi mdi-arrow-bottom-right"></i> -10%</h4> */}
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-md-6 col-12">
					<div class="box">
						<div class="box-body">
							<h4 class="fw-500 mt-0">Pending Leave Count</h4>
							<div class="d-flex align-items-center justify-content-between">
								<h2 class="fw-600 my-0">{pending}</h2>
								{/* <h4 class="fw-500 my-0 text-success"><i class="me-10 mdi mdi-arrow-top-right"></i> +12%</h4> */}
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-md-6 col-12">
					<div class="box">
						<div class="box-body">
							<h4 class="fw-500 mt-0">Leaders Count</h4>
							<div class="d-flex align-items-center justify-content-between">
								<h2 class="fw-600 my-0">{leaders}</h2>
								{/* <h4 class="fw-500 my-0 text-danger"><i class="me-10 mdi mdi-arrow-bottom-right"></i> -11%</h4> */}
							</div>
						</div>
					</div>
				</div>
				<Slider/>
				</div>
		</section>
		{/* <!-- /.content --> */}
	  </div>
  </div>
  {/* <!-- /.content-wrapper --> */}
  <Footer/>
  </div>
	
	<Helmet>
	{/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script> */}
    </Helmet>

	
</div>
  )
}
