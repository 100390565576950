import React,{useEffect,useState} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Slider() {
  const [carouselList, setcarouselList] = useState([]);
  console.log(carouselList);
  const carousel = async () => {
    axios({
        'method':'GET',
        'url': 'https://node.firehrm.com/HRMS/HRMS/carousal/getAllCarousel', 
    })
    .then((responce) => {
        console.log(responce)
        setcarouselList(responce?.data?.data)
    })
    .catch((error) => {
        console.log(error)
    })
} 

useEffect(() =>  {
    carousel();
}, []);
// let imgPath
//   const images = carouselList;
//   console.log(images,'images');
//   console.log(imgPath);
//   const [carosel, setCarosels] = useState('')
//   const theme = useTheme();
//   const [activeStep, setActiveStep] = React.useState(0);
//   const maxSteps = images.length;

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleStepChange = (step) => {
//     setActiveStep(step);
//   };

//   const carosels = async () => {
//     axios({
//         'method':'GET',
//         'url':'https://node.firehrm.com/HRMS/HRMS/carousal/getAllCarousel',
//     })
//     .then((response) =>{
//         console.log(response)
//        setCarosels(response.data.data)
//     })
//     .catch((error) =>{
//         console.log(error)
//     })
//   }
  
//   useEffect(() => {carosels();},[]);
  const state = {
    responsive: {
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },

}
  
  return (
    // <Box sx={{ maxWidth: 800, flexGrow: 1,marginTop:'20px' }} class="pt-5">
    //   <Paper
    //     square
    //     elevation={0}
    //     sx={{
    //       display: 'flex',
    //       alignItems: 'center',
    //       height: 50,
    //       pl: 5,
    //       bgcolor: 'transparent',
    //     }}
    //   >
    //     <Typography class="fw-bolder h1" style={{'font-family': "Times New Roman"}}>{images[activeStep]?.carousalImageTitle}</Typography>
    //   </Paper>
    //   <AutoPlaySwipeableViews
    //     axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    //     index={activeStep}
    //     onChangeIndex={handleStepChange}
    //     enableMouseEvents
    //   >
    //     {carouselList.map((x, index) => (
    //       <div key={index.label}>
    //         {Math.abs(activeStep - index) <= 2 ? (
    //           <Box
    //             component="img"
    //             sx={{
    //               height: 555,
    //               display: 'block',
    //               maxWidth: 1800,
    //               overflow: 'hidden',
    //               width: '100%',
    //             }}
    //             src={x?.carousalImage}
    //             alt={index.label}
    //           />
    //         ) : null}
    //       </div>
    //     ))}
    //   </AutoPlaySwipeableViews>
    //   <MobileStepper
    //     steps={maxSteps}
    //     position="static"
    //     activeStep={activeStep}
       
    //     nextButton={
    //       <Button
    //         size="small"
    //         onClick={handleNext}
    //         disabled={activeStep === maxSteps - 1}
    //       >
    //         Next
    //         {theme.direction === 'rtl' ? (
    //           <KeyboardArrowLeft />
    //         ) : (
    //           <KeyboardArrowRight />
    //         )}
    //       </Button>
    //     }
    //     backButton={
    //       <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
    //         {theme.direction === 'rtl' ? (
    //           <KeyboardArrowRight />
    //         ) : (
    //           <KeyboardArrowLeft />
    //         )}
    //         Back
    //       </Button>
    //     }
    //   />
    // </Box>

    <OwlCarousel items={5}
             className="owl-theme"
             loop
             nav
             margin={3}
             autoplay={true}
             dots={false}
             responsive={state.responsive}
             animateOut={'fadeOut'}
             animateIn={'flipInX'}>
 {carouselList.map((x) => (               
           
<div class="card">
  <img src={x?.carousalImage} alt="Avatar" style={{width:"100%"}} width="512px" height="372px"/>
  <div class="container">
    <h4><b>{x?.carousalImageTitle}</b></h4>
  </div>
</div>
 )
 )
}  
         
          </OwlCarousel>
  );
}

export default Slider;

