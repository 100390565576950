import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function AdminViewsingleLeave(x) {
  const state = useLocation()
  const states = state
  console.log(states);
  const [LeaveApplicationData, setLeaveApplicationData] = useState([]);
  const Id = states?.state?._id
  const token = localStorage.getItem('token');
  const nav = useNavigate()
  console.log(token)

     const LeaveApplicationList = async () => {
    
      axios({
             'method':'GET',
             'url':'https://node.firehrm.com/HRMS/HRMS/employee/getById-leave/'+Id
         
         }).then((response) => {
           let res = response.data
           
           console.log(res,"leaveAppStatus")
           setLeaveApplicationData(res?.data)
         })
         .catch((error) =>{
             console.log(error)
         })
     }
 
     useEffect(() => {
      LeaveApplicationList();
  },[]);

  function Accept(){
  axios({
    'method':'PUT',
    'url':'https://node.firehrm.com/HRMS/HRMS/employee/acceptLeave/'+Id,
    headers:{
        'Authorization':token,
        'Content-type': 'application/JSON'
      }

}).then((response) => {
  let res = response.data
  
  console.log(res)
  if(res.status='200'){nav('/getallleaveapplication')}
})
.catch((error) =>{
    console.log(error)
})
}

function Reject(){
    axios({
      'method':'PUT',
      'url':'https://node.firehrm.com/HRMS/HRMS/employee/rejectLeave/'+Id,
      headers:{
        Authorization:token,
        'Content-type': 'application/JSON'
      }
  
  }).then((response) => {
    let res = response.data
    
    console.log(res)
    if(res.status='200'){nav('/getallleaveapplication')}
  })
  .catch((error) =>{
      console.log(error)
  })
  }
 

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="col-11 ms-50">

<div class="box p-50">
   <div class="box-header with-border">
     <h3 class="box-title">Leave Application</h3>
   </div>
   {/* <!-- /.box-header --> */}
   <div class="box-body">
       <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
           <thead>
               <tr>
                   <th>Name</th>
                   <td>{LeaveApplicationData?.employeeDetails?.name}</td>
               </tr>
               <tr>
                   <th>Email-ID</th>
                   <td>{LeaveApplicationData?.employeeDetails?.email}</td>
               </tr>
               <tr>
                   <th>Mobile Number</th>
                   <td>{LeaveApplicationData?.employeeDetails?.contact}</td>
               </tr>
               <tr>
                   <th>Designation</th>
                   <td>{LeaveApplicationData?.employeeDetails?.designation?.designationName}</td>
               </tr>
               <tr>
                   <th>Identity Number</th>
                   <td>{LeaveApplicationData?.employeeDetails?.identityNumber}</td>
               </tr>
               <tr>
                   <th>From Date</th>
                   <td>{LeaveApplicationData?.fromDate}</td>
               </tr>
               <tr>
                   <th>To Date</th>
                   <td>{LeaveApplicationData?.toDate}</td>
               </tr>
               <tr>
                   <th>Full/Half Day</th>
                   <td>{LeaveApplicationData?.fullOrHalfDay}</td>
               </tr>
               <tr>
                   <th>Reason</th>
                   <td>{LeaveApplicationData?.reason}</td>
               </tr>
               <tr>
                   <th>Message</th>
                   <td>{LeaveApplicationData?.reasonInDetails}</td>
               </tr>
               <tr>
                   <th>Medical Document</th>
                   <iframe class="col pe-5 me-5" src={LeaveApplicationData?.uploadDocument}></iframe>
               </tr>
               <tr>
                   <th>Team Leader</th>
                   <td>{LeaveApplicationData?.employeeDetails?.designation?.teamLeader}</td>
               </tr>
               <tr>
                   <th>Project Manager</th>
                   <td>{LeaveApplicationData?.employeeDetails?.designation?.projectManager}</td>
               </tr>
               <tr>
                   <th>Status</th>
                   <td>{LeaveApplicationData?.leaveApplyStatus}</td>
               </tr>
               {LeaveApplicationData?.leaveApplyStatus == 'pending'?
               <tr>
                   <th>Action</th>
                   <td><div><button class='btn btn-success me-200' onClick={(e)=>Accept(e)}>Accept</button><button onClick={(e)=>Reject(e)} class='btn btn-danger ms-200'>Reject</button></div></td>
               </tr>
               :''}
           </thead>

       </table>
       </div>
   </div>
   {/* <!-- /.box-body --> */}
 </div>
 {/* <!-- /.box -->       */}
</div>

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
