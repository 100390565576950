import React from 'react'

export default function QuickActionToggle() {
  return (
    <div>
        {/* <!-- quick_actions_toggle --> */}
  <div class="modal modal-right fade" id="quick_actions_toggle" tabindex="-1">
	  <div class="modal-dialog">
		<div class="modal-content slim-scroll">
		  <div class="modal-body bg-white p-30">
			<div class="d-flex align-items-center justify-content-between pb-30">
				<h4 class="m-0">Quick Actions<br/>
				{/* <small class="badge fs-12 badge-primary mt-10">23 tasks pending</small> */}
                </h4>
				<a href="#" class="btn btn-icon btn-danger-light btn-sm no-shadow" data-bs-dismiss="modal">
					<span class="fa fa-close"></span>
				</a>
			</div>
            <div class="row">
                <div class="col-6">
                {localStorage.getItem('role')=='admin'?<a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="viewAllPayslip">
                        <i class="fa-solid fa-file-invoice-dollar"></i>
                        <span class="fs-16">Payslip</span>
                    </a>:
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="monthlyPayslipForEmployee">
                        <i class="fa-solid fa-file-invoice-dollar"></i>
                        <span class="fs-16">Payslip</span>
                    </a>}
                </div>
                <div class="col-6">
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="organizationDetails">
                        <i class="fa-solid fa-users"></i>
                        <span class="fs-16">Organisation</span>
                    </a>
                </div>
                <div class="col-6">
                {localStorage.getItem('role')=="employee"?<a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="viewEmployeeProfile">
                        <i class="fa-solid fa-diagram-project"></i>
                        <span class="fs-16">Profile</span>
                    </a>
                    :
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="viewAllEmployee">
                        <i class="fa-solid fa-diagram-project"></i>
                        <span class="fs-16">Employees Profile</span>
                    </a>}
                </div>
                <div class="col-6">
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="leadersList">
                        <i class="fa-solid fa-user-tie"></i>
                        <span class="fs-16">Leaders</span>
                    </a>
                </div>
                <div class="col-6">
                {localStorage.getItem("role")=='admin'?<a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="getallleaveapplication">
                        <i class="fa-solid fa-envelope"></i>
                        <span class="fs-16">Leave Application</span>
                    </a>:
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="leaveApplicationHistory">
                    <i class="fa-solid fa-envelope"></i>
                    <span class="fs-16">Leave Application</span>
                </a>}
                </div>
                {/* <div class="col-6">
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="setting.html">
                        <i class="fa-solid fa-gear"></i>
                        <span class="fs-16">Settings</span>
                    </a>
                </div> */}
                <div class="col-6">
                {localStorage.getItem('role')=='admin'?<a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="viewSAP">
                        <i class="fa-brands fa-first-order"></i>
                        <span class="fs-18">Feedback</span>
                    </a>:
                    <a class="waves-effect waves-light btn btn-app btn btn-primary-light btn-flat mx-0 mb-20 no-shadow py-35 h-auto d-block" href="createSAP">
                    <i class="fa-brands fa-first-order"></i>
                    <span class="fs-18">Feedback</span>
                </a>}
                </div>
			</div>
		  </div>
		</div>
	  </div>
  </div>
  {/* <!-- /quick_actions_toggle -->   */}
    </div>
  )
}
