import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import {useLocation, useNavigate } from 'react-router-dom'

export default function UpdatePayslip() {
    const state = useLocation()
    console.log(state);
    const navigate2 = useNavigate();
    const [identityNumber,setIdentityNumber] = useState(state?.state?.identityNumber);
    const [basicPay,setBasicPay] = useState(state?.state?.basicPay);
    const [employeeShareOfPF, setEmployeeShareOfPF] = useState(state?.state?.employeeShareOfPF);
    const [HRA,setHRA] = useState(state?.state?.HRA);
    const [employeeShareOfESI,setEmployeeShareOfESI] = useState(state?.state?.employeeShareOfESI);
    const [medicalAllowance,setMedicalAllowance] = useState(state?.state?.medicalAllowance);
    const [employerShareOfPF,setEmployerShareOfPF] = useState(state?.state?.employerShareOfPF);
    const [conveyanceAllowance,setConveyanceAllowance] = useState(state?.state?.conveyanceAllowance);
    const [employerShareOfESI,setEmployerShareOfESI] = useState(state?.state?.employerShareOfESI);
    const [cityCompensatory,setCityCompensatory] =useState(state?.state?.cityCompensatory);
    const [allowance, setAllowance] = useState(state?.state?.allowance);
    const [lossOfPay,setlossOfPay] = useState(state?.state?.lossOfPay);
    const [otherAllowance,setOtherAllowance] = useState(state?.state?.otherAllowance);
    const [otherDeduction,setOtherDeduction] = useState(state?.state?.otherDeductions);
    const [TDS,setTDS] = useState(state?.state?.TDS);
    const [monthAndYear, setMonthAndYear] = useState(state?.state?.monthAndYear);

    // const payslipDetails = {identityNumber,otherDeduction,monthAndYear,basicPay,employeeShareOfPF,lossOfPay,HRA,employeeShareOfESI,medicalAllowance,employerShareOfPF,conveyanceAllowance,employerShareOfESI,cityCompensatory,otherAllowance,allowance,TDS}
    // console.log(payslipDetails);

    function updatePayslip(e) {
        e.preventDefault();
        // console.log(payslipDetails)
        const token = localStorage.getItem('token')
         fetch('https://node.firehrm.com/HRMS/HRMS/admin/update-payslip/'+state?.state?._id,{
          method: 'PUT',
          headers: {            
              'Authorization': token,
              'Content-Type': 'application/json'
          },
          
          body: JSON.stringify({identityNumber,otherDeduction,monthAndYear,basicPay,employeeShareOfPF,lossOfPay,HRA,employeeShareOfESI,medicalAllowance,employerShareOfPF,conveyanceAllowance,employerShareOfESI,cityCompensatory,otherAllowance,allowance,TDS})
          
      })
      .then((response)=>{
        console.log(response)
        if(response.status=='200'){
           navigate2("/viewAllPayslip")
        }
      })
      .catch((error)=>{
        console.log(error);
      })

    }


  return (
    <div class=" layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-200 ms-50 me-50">
        <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="row">

  <div class="col-lg-12">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><strong>UPDATE PAYSLIP</strong></h3>
      </div>
      <div class="panel-body p-50">
<form>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>ID.NO</label>
                   <input class='col-6' value={identityNumber} type="text" onChange={(e)=>setIdentityNumber(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Month And Year</label>
                   <input class='col-6' value={monthAndYear} type="month" onChange={(e)=>setMonthAndYear(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Basic Pay</label>
                   <input class='col-6' value={basicPay} type="text" onChange={(e)=>setBasicPay(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Loss Of Pay</label>
                   <input class='col-6' value={lossOfPay} type="text" onChange={(e)=>setlossOfPay(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>HRA</label>
                   <input class='col-6' value={HRA} type="text" onChange={(e)=>setHRA(e.target.value)}/>
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Employee Share Of PF</label>
                   <input class='col-6' value={employeeShareOfPF} type="text" onChange={(e)=>setEmployeeShareOfPF(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Employee Share Of ESI</label>
                   <input class='col-6' value={employeeShareOfESI} type="text" onChange={(e)=>setEmployeeShareOfESI(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Employer Share Of PF</label>
                   <input class='col-6' value={employerShareOfPF} type="text" onChange={(e)=>setEmployerShareOfPF(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Employer Share Of ESI</label>
                   <input class='col-6' value={employerShareOfESI} type="text" onChange={(e)=>setEmployerShareOfESI(e.target.value)} />
               </div>
               
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Medical Allowance</label>
                   <input class='col-6' value={medicalAllowance} type="text" onChange={(e)=>setMedicalAllowance(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Conveyance Allowance</label>
                   <input class='col-6' value={conveyanceAllowance} type="text" onChange={(e)=>setConveyanceAllowance(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>City Compensatory</label>
                   <input class='col-6' value={cityCompensatory} type="text" onChange={(e)=>setCityCompensatory(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Allowance</label>
                   <input class='col-6' value={allowance} type="text" onChange={(e)=>setAllowance(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Other Allowance</label>
                   <input class='col-6' value={otherAllowance} type="text" onChange={(e)=>setOtherAllowance(e.target.value)} />
               </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>TDS</label>
                   <input class='col-6' value={TDS} type="text" onChange={(e)=>setTDS(e.target.value)} />
                   </div>
               <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'> 
                   <label class='col-6'>Other Deduction</label>
                   <input class='col-6' value={otherDeduction} type="text" onChange={(e)=>setOtherDeduction(e.target.value)} />
                   </div>
               {/* const payslipDetails = {identityNumber,otherDeduction,monthAndYear,basicPay,employeeShareOfPF,lossOfPay,HRA,employeeShareOfESI,medicalAllowance,employerShareOfPF,conveyanceAllowance,employerShareOfESI,cityCompensatory,otherAllowance,allowance,TDS} */}
               <div class="mt-4" style={{marginLeft:'500px'}}>
            <input type="button" onClick={(e)=>updatePayslip(e)} class="btn btn-primary px-100 mt-50" value="SAVE" />
        </div>

        </form>
    </div>
    </div>
  </div>
</div>
<div class="row">
     <div class="col-lg-12" id="images">
  </div>
</div>
    </div>	
    <Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>	   */}
</Helmet>

</div>
  )
}
