import React,{useState} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

export default function EditEmployeeProfile() {
    const token = localStorage.getItem('token')
    const updateEmpProfile = useNavigate()
    const {state}=useLocation();
    console.log(state._id)
    console.log(state)
    const [profileImage,setProfileImage]=useState(state?.profileImage);
    const [profileImage1,setProfileImage1]=useState([]);
    const [name,setName]=useState(state?.name);
    const [email,setEmail]=useState(state?.email);
    const [password, setCreatePassword] = useState('')
    const [contact,setContactNumber]=useState(state?.contact);
    const [designationName,setDesignationName]=useState(state?.designation?.designationName);
    const [identityNumber,setIdentityNumber]=useState(state?.identityNumber); 
    const [gender,setGender]=useState(state?.gender);
    const [dateOfJoining,setDateOfJoining]=useState(state?.dateOfJoining);
    const [yearsOfExperience,setYearsOfExperience]=useState(state?.yearsOfExperience);
    const [teamLeader,setTeamLeader]=useState(state?.designation?.teamLeader);
    const [projectManager,setProjectManager]=useState(state?.designation?.projectManager);
    const [dateOfBirth,setDateOfBirth]=useState(state?.dateOfBirth);
    const [bloodGroup,setBloodGroup]=useState(state?.bloodGroup);
    const [address,setAddress]=useState(state?.address);
    const [uploadDocument,setUploadDocument]=useState(state?.uploadDocument);
    const [uploadDocument1,setUploadDocument1]=useState([]);
    const [uanNumber, setuanNumber] = useState(state?.bankDetails?.uanNumber);
    const [panNumber, setpanNumber] = useState(state?.bankDetails?.panNumber);
    const [esiNumber, setesiNumber] = useState(state?.bankDetails?.esiNumber)
    const [pfNumber, setpfNumber] = useState(state?.bankDetails?.pfNumber)
    const [accountNumber, setaccountNumber] = useState(state?.bankDetails?.accountNumber)
    const [branchName, setbranchName] = useState(state?.bankDetails?.branchName)
    const [employeeStatus, setemployeeStatus] = useState(state?.bankDetails?.employeeStatus);
    const accountHolderName = name
    const [IFSCCode, setIFSCCode] = useState(state?.bankDetails?.IFSCCode)

    const designation = {designationName,teamLeader,projectManager}
    const bankDetails = {
        uanNumber,
        panNumber,
        esiNumber,
        pfNumber,
        accountNumber,
        branchName,
        accountHolderName,
        IFSCCode
        }
    const updateEmpData ={employeeStatus,password,bankDetails,designation,profileImage,name,email,contact,identityNumber,gender,dateOfJoining,yearsOfExperience,dateOfBirth,bloodGroup,address,uploadDocument}
    console.log(updateEmpData);

    const UpdateEmployeeProfile = async (e) => {
        e.preventDefault();
        UploadDocument()
        UploadImage()
        let updateEmployeeData= await fetch('https://node.firehrm.com/HRMS/HRMS/employee/update/'+state._id,{
            method: 'PUT',
            headers: {
                'Authorization':token,
              'Content-type': 'application/JSON'
            },
            body: JSON.stringify(updateEmpData)
        }).then((response)=>{
            console.log(response)
           updateEmpProfile('/viewAllEmployee')
        }).catch((error)=>{
            console.log(error)
        })
        updateEmployeeData = await updateEmployeeData.json()
        console.log(updateEmployeeData)
    }
    async function UploadImage() {
        const formdata1=new FormData()
        formdata1.append('image',profileImage1)
        console.log(profileImage1);
        await fetch('https://node.firehrm.com/HRMS/HRMS/admin/image',{
        method: 'POST',
        body:formdata1
    }).then((response)=>{
        console.log(response)
        return response.json()
    }).then((resp)=>{console.log(resp);setProfileImage(resp.data.image); console.log(profileImage);} )
    .catch((error)=>{
        console.log(error)
    })
}

async function UploadDocument() {
    const formdata2=new FormData()
    formdata2.append('image',uploadDocument1)
    await fetch('https://node.firehrm.com/HRMS/HRMS/admin/image',{
    method: 'POST',
    body:formdata2
}).then((response)=>{
    // console.log(response)
    return response.json()
}).then((resp)=>{console.log(resp);
     setUploadDocument(resp.data.image); 
    console.log(profileImage);} )
.catch((error)=>{
    console.log(error)
})
}
    

  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150 ms-50 me-50">
        <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="row">

  <div class="col-lg-12">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><strong>EDIT EMPLOYEE</strong></h3>
      </div>
      <div class="panel-body p-50">
        <form>
        <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="image">Profile Image</label>
                        <input class="col-6" type="file" name="image" id="image" onChange={(e)=>setProfileImage1(e.target.files[0])} />
                        {/* <input type="button" onClick={(e)=>UploadImage(e)} class="btn btn-primary col-2 sButPad  fw-bolder" value="Upload" /> */}
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="name">Name</label>
                        <input placeholder={name} class="col-6" type="text" name="name" id="name"  onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="email">E-Mail</label>
                        <input placeholder={email} class="col-6" type="email" name="email" id="email"  onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="mobNumber">Mobile Number</label>
                        <input placeholder={contact} class="col-6" type="number" name="mobNumber" id="mobNumber"  onChange={(e)=>setContactNumber(e.target.value)} />
                    </div>
                    {/* <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="password">Create Password</label>
                        <input class="col-6" type="password" name="password" id="password" onChange={(e)=>setCreatePassword(e.target.value)} />
                    </div> */}
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="designation">Designation</label>
                        <input placeholder={designationName} class="col-6" type="text" name="designation" id="designation"  onChange={(e)=>setDesignationName(e.target.value)} />
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="id.no">Identity Number</label>
                        <input placeholder={identityNumber} class="col-6" type="number" name="id.no" id="id.no"  onChange={(e)=>setIdentityNumber(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <div class="col-6"><label htmlhtmlFor="gender">Select Employee Status</label></div>
                        <select defaultValue={employeeStatus} class="col-6"  aria-label="Default select example"onChange={(e)=>setemployeeStatus(e.target.value)}>
                                <option value="">Employee Status</option>
                                <option value="member">Member</option>
                                <option value="leader">Leader</option>
                        </select>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <div class="col-6"><label htmlhtmlFor="gender">Select Gender</label></div>
                        <select defaultValue={gender} class="col-6"  aria-label="Default select example"onChange={(e)=>setGender(e.target.value)}>
                                <option value="">Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                        </select>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="dateOfJoining">Date of Joining</label>
                        <input placeHolder={dateOfJoining} class="col-6" type="date" name="dateOfJoining" id="dateOfJoining"  onChange={(e)=>setDateOfJoining(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="experience">Years of Experience</label>
                        <input placeHolder={yearsOfExperience} class="col-6" type="number" name="experience" id="experience"  onChange={(e)=>setYearsOfExperience(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="teamLeader">Team Leader</label>
                        <input placeHolder={teamLeader} class="col-6" type="text" name="teamLeader" id="teamLeader" onChange={(e)=>setTeamLeader(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="projectManager">Project Manager</label>
                        <input placeHolder={projectManager} class="col-6" type="text" name="projectManager" id="projectManager" onChange={(e)=>setProjectManager(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="dateOfBirth">Date of birth</label>
                        <input placeHolder={dateOfBirth} class="col-6" type="date" name="dateOfBirth" id="dateOfBirth" onChange={(e)=>setDateOfBirth(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">Blood Group</label>
                        <input placeHolder={bloodGroup} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setBloodGroup(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="address">Address</label>
                        <textarea placeHolder={address} class="col-6" name="address" id="address" onChange={(e)=>setAddress(e.target.value)}></textarea>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="documents">Upload Resume</label>
                        <input placeHolder={uploadDocument} class="col-4" type="file" name="" id="" onChange={(e)=>setUploadDocument1(e.target.files[0])}/>
                        {/* <input type="button" onClick={(e)=>UploadDocument(e)} class="btn btn-primary col-2 sButPad  fw-bolder" placeHolder='Submit'/> */}
                    </div>
                    <h4 class='m-10 p-10'>Bank Details</h4>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">Account Number</label>
                        <input placeHolder={accountNumber} class="col-6" type="tel" name="bloodGroup" id="bloodGroup" onChange={(e)=>setaccountNumber(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">Branch Name</label>
                        <input placeHolder={branchName} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setbranchName(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">IFSC Code</label>
                        <input placeHolder={IFSCCode} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setIFSCCode(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">Pan Number</label>
                        <input placeHolder={panNumber} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setpanNumber(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">UAN Number</label>
                        <input placeHolder={uanNumber} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setuanNumber(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">ESI Number</label>
                        <input placeHolder={esiNumber} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setesiNumber(e.target.value)}/>
                    </div>
                    <div class='row pe-1 pt-1 pb-1 mb-5 mt-3'>
                        <label class="col-6" htmlhtmlFor="bloodGroup">PF Number</label>
                        <input placeHolder={pfNumber} class="col-6" type="text" name="bloodGroup" id="bloodGroup" onChange={(e)=>setpfNumber(e.target.value)}/>
                    </div>
                    
                    <div class="mt-30" style={{marginLeft:'600px'}}>
                        <input type="button" onClick={(e)=>UpdateEmployeeProfile(e)} class="btn btn-primary br butPad px-50 py-2 my-5 fw-bolder" value="Save" />
                    </div>
          {/* <button onClick={(e)=>UpdateEmployeeProfile(e)} class="btn btn-primary" id="add-employee-btn" type="button">Submit</button> */}
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row">
     <div class="col-lg-12" id="images">
  </div>
</div>
    </div>	
    <Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

	<script src="assets/js/vendors.min.js"></script>
	<script src="assets/js/pages/chat-popup.js"></script>
    <script src="assets/icons/feather-icons/feather.min.js"></script>	

	<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>
	
	{/* <!-- HRMS App --> */}
	<script src="assets/js/jquery.smartmenus.js"></script>
	<script src="assets/js/menus.js"></script>
	<script src="assets/js/template.js"></script>
	<script src="assets/js/pages/dashboard.js"></script>
	<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
	<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
	<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
	{/* <script>
		$(document).ready(function(){
		$('.dropdown-toggle').dropdown()
	});
	</script>	   */}
</Helmet>

</div>


  )
}
