import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../componenet.jsx/footer'
import Header from '../../componenet.jsx/header'
import QuickActionToggle from '../../componenet.jsx/quickActionToggle'
import UserSideBar from '../../componenet.jsx/userSideBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useLocation } from 'react-router-dom';

export default function ViewOpening() {
    const state = useLocation()
    console.log(state);
    const [Vacancy, setVacancy] = useState([]);
    const [candidate, setcandidate] = useState('');
    const token = localStorage.getItem('token')
    

    const getVacancy = async () => {
     axios({
            'method':'GET',
            'url':'https://node.firehrm.com/HRMS/HRMS/admin/getById-vacancy/'+state?.state?._id
            
        }).then((response) => {
            console.log(response)
            setVacancy(response?.data?.data?.[0])
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    useEffect(() => {getVacancy();},[]);

    const nav = useNavigate()

    function NavToEdit(e,x){
        nav('/updateVacancy',{state:x})
    }

    function Delete(e){
        axios({
            'method':'DELETE',
            'url': 'https://node.firehrm.com/HRMS/HRMS/admin/delete-vacancy/'+state?.state?._id,
            'headers':{
                'Authorization':token
            }   
        })
        .then((responce) => {
            console.log(responce)
            if(responce.status=='200'){nav('/getAllOpenings')}
        })
        .catch((error) => {
            console.log(error)
        })
        }

        function createCandidate(e,x){
            nav('/createCandidate',{state:x})
        }

        const getCandidate = async () => {
            axios({
                   'method':'GET',
                   'url':'https://node.firehrm.com/HRMS/HRMS/admin/getAll-interview/'+state?.state?._id,
                   headers:{
                    "Authorization":token
                   }
                   
               }).then((response) => {
                   console.log(response)
                    setcandidate(response?.data?.data)
               })
               .catch((error) =>{
                   console.log(error)
               })
           }
       
           useEffect(() => {getCandidate();},[]);

           function navtoview(e,x){
            nav('/viewCandidate',{state:x})
           }


  return (
    <div class="layout-top-nav light-skin theme-primary fixed">
	
    <div class="wrapper mt-150">
    <Header/>

<UserSideBar/>

<QuickActionToggle/>

<div class="col-11 ms-50">

<div class="box p-50">
   <div class="box-header with-border">
     <h3 class="box-title" style={{marginRight:"400px"}}>JOB VACANCY</h3>
     <button onClick={(e)=>createCandidate(e,Vacancy)} class='btn btn-success' style={{marginLeft:'130px',paddingRight:'25px',paddingLeft:'25px'}}>ADD CANDIDATE</button>
     <button onClick={(e)=>NavToEdit(e,Vacancy)} class='btn btn-info' style={{marginLeft:'130px',paddingRight:'25px',paddingLeft:'25px'}}>EDIT</button>
   </div>
   {/* <!-- /.box-header --> */}
   <div class="box-body">
   <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
           <thead>
                <tr>
                   <th>Created On</th>
                   <td>{Vacancy?.createdAt}</td>
               </tr>
               <tr>
                   <th>Number Of Vacancy</th>
                   <td>{Vacancy?.noOfVacancy}</td>
               </tr>
               <tr>
                   <th>Role</th>
                    <td>{Vacancy?.role}</td>
               </tr>
               <tr>
                   <th>Team</th>
                   <td>{Vacancy?.team}</td>
               </tr>
           </thead>
           
       </table>
       <div style={{marginLeft:'1200px'}}>
       <button onClick={(e)=>Delete(e)} class='btn btn-danger'>DELETE</button>
       </div>
       </div>
       
   </div>
  
 </div>

 <div class="box p-50">
   <div class="box-header with-border">
     <h3 class="box-title" style={{marginRight:"700px"}}>Candidates</h3>
   </div>
   {/* <!-- /.box-header --> */}
   <div class="box-body">
   <div class="table-responsive">
         <table id="example5" class="table table-bordered table-striped" style={{width:"100%"}}>
           <thead>
                <tr>
                   <th>Name</th>
                   <th>Action</th>
               </tr>
           </thead>
           <tbody>
            {candidate && candidate.map((x=>
                <tr>
                    <th>{x?.screening?.candidateName}</th>
                    <th><button onClick={(e)=>navtoview(e,x)} className="bg-primary" ><i class="fa fa-eye" aria-hidden="true"></i></button></th>
                </tr>
            ))}
           </tbody>
           
       </table>
       
       </div>
       
   </div>
  
 </div>

</div>

<Footer/> 

<Helmet>    	{/* <!-- Vendor JS --> */}
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/vendors.min.js"></script>
<script src="assets/js/pages/chat-popup.js"></script>
<script src="assets/icons/feather-icons/feather.min.js"></script>	

<script src="assets/vendor_components/apexcharts-bundle/dist/apexcharts.js"></script>

{/* <!-- HRMS App --> */}
<script src="assets/js/jquery.smartmenus.js"></script>
<script src="assets/js/menus.js"></script>
<script src="assets/js/template.js"></script>
<script src="assets/js/pages/dashboard.js"></script>
<script type="text/javascript" src="Scripts/jquery-2.1.1.min.js"></script>
<script type="text/javascript" src="Scripts/bootstrap.min.js"></script>
<script type="text/javascript" src="js/bootstrap/bootstrap-dropdown.js"></script>
{/* <script>
$(document).ready(function(){
$('.dropdown-toggle').dropdown()
});
</script>	   */}
</Helmet>
</div>
</div>
  )
}
